import React, { Component } from 'react';


import './UiCheckList.scss';

import checkIcon from '../../assets/images/ui/check.svg';

class UiCheckbox extends Component {

    state = {
        checkActive: true,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.value != undefined) {
            this.setState({ checkActive: this.props.value })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clear != undefined && this.props.clear) {
            this.props.clearCallBack(false);
            this.setState({ checkActive: false });
        }
    }



    render() {
        return (
            <div className="check-list">
                <button className="check-item"
                    disabled={this.props.disabled}
                    onClick={() => {
                        this.setState({ checkActive: !this.state.checkActive }, () => {
                            this.props.callBack(this.state.checkActive ? true : false);
                        });
                    }}
                >

                    <div className={"check-btn" + (this.state.checkActive ? " active" : "")}>
                        {this.state.checkActive ?
                            <img src={checkIcon} /> : null
                        }
                    </div>

                    {this.props.labelDisabled != '' ?
                        <button className="check-name"><span>{this.props.labelDisabled}</span></button> : null
                    }
                </button>
            </div>


        );
    }
}
export default UiCheckbox;