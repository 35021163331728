import React, { Component } from "react";

import "./UiModal.scss";
import "./UiModalLogin.scss";

import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-zmdi/close";

import UiBtnLight from "../buttons/UiBtnLight";
import UiBtnPink from "../buttons/UiBtnPink";
import UiModal from "./UiModal";
import UiTextInput from "../forms/UiTextInput";
import UiTextInputMasked from "../forms/UiTextInputMasked";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { saveUser } from "../../services/Auth";
import {
  registrationUser,
  loginUser,
  setUserMetrics,
} from "../../services/Users";
import { newClient } from "../../services/Loyalty";

class UiModalLogin extends Component {
  state = {
    regBtnActive: true,
    modalActive: false,
    loginStep: false,
    passTimer: 60,
    loader: false,

    phone: "",
    code: "",

    token: "",
    capCode: null,
    captcha: null,
    
  };

  constructor(props) {
    super(props);
  }


  componentDidMount() {
    this.timer = setInterval(() => {
      let elements =  document.querySelectorAll("input[name=smart-token]");
      if(elements){
        if(elements.length > 0) this.setState({ token: elements[0]?.value }); 
      } 
    }, 1000);
  }

  componentWillUnmount () {
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.passTimer === 0) clearInterval(this.interval);

    if (this.props.modalOpen && prevProps.modalOpen != this.props.modalOpen) {
      const script = document.createElement("script");
      script.src = "https://smartcaptcha.yandexcloud.net/captcha.js"; // whatever url you want here
      script.charset = "utf-8";
      script.async = true;
      script.onload = function () {
        console.log("hell");
      };
      document.head.appendChild(script);
    }
  }

  _passTimer() {
    this.setState({ loginStep: 1 });
  }

  _validatePhone(val) {
    return val ? val.match(/\d/g).length > 9 : false;
  }

  _register() {
    let token = document.querySelectorAll("input[name=smart-token]")[0]?.value;
    if (token.length > 0) {
      if (this.state.regBtnActive) {
        this.setState({ loader: true, regBtnActive: true });
        var _phone = this.state.phone.replace(/ /g, "");
        _phone = _phone.replace(/\+7/g, "7");
        _phone = _phone.replace(/\(/g, "");
        _phone = _phone.replace(/\)/g, "");
        _phone = _phone.replace(/-/g, "");

        registrationUser(_phone, token).then((res) => {
          console.log(res);
          if (res.success) {
            this.setState({
              loader: false,
              loginStep: true,
              regBtnActive: false,
              phone: _phone,
              passTimer: 60,
            });
            this.interval = setInterval(
              () =>
                this.setState((prevState) => ({
                  passTimer: prevState.passTimer - 1,
                })),
              1000
            );
          } else {
            this.setState({ loader: false, error1: true });
          }
        });
      }
    }
  }

  _login() {
    this.setState({ loader: true });
    loginUser(this.state.phone, this.state.code).then((res) => {
      console.log(res);
      this.setState({ loader: false });
      if (res?.success) {
        if (res?.response) {
          if (res?.response?.id) {
            //отправка метрики в crm

            window.ym(55588969, "getClientID", function (clientID) {
              setUserMetrics(res.response.phone, clientID).then(
                (metricsRes) => {
                  console.log("metricsRes", metricsRes);
                }
              );
            });

            saveUser(res?.response);

            if (res?.response?.id) {
              console.log("loyalty: " + JSON.stringify(res?.response));
              newClient(res?.response);
            }

            this.props.callBack(res.response);
          } else {
            this.setState({ error2: true });
          }
        } else {
          this.setState({ error2: true });
        }
      } else {
        this.setState({ error1: true });
      }
    });
  }

  render() {
    const modalInner = (
      <div className="modal-login">
        <div className="modal-body">
          <button
            className="modal-close-btn"
            onClick={() => {
              this.setState({ loginStep: false });
              clearInterval(this.interval);
              this.props.modalClose();
            }}
          >
            <Icon className="modal-close-btn-icon" icon={closeIcon} />
          </button>
          <div className="login-form">
            <h3>Войти</h3>
            <p>Мы привяжем ваш номер к заказу и точно ничего не потеряем</p>

            <PhoneInput
              country={"ru"}
              placeholder="Введите номер телефона"
              value={this.state.phone}
              disabled={this.state.loginStep}
              onChange={(phone) => this.setState({ phone })}
            />

            <div
              id="captcha-container"
              className="smart-captcha"
              data-sitekey="ysc1_DuWgX9pnuXCXA04HS3vLVH5zxowlvKqg0vthWnwd41b0e6dc"
              style={{ height: "125px" , marginTop: "10px" }}
            ></div>

            {!this.state.loginStep ? (
              <div className="login-send-pass">
                <UiBtnPink
                  loaderActive={this.state.loader}
                  disabled={!this._validatePhone(this.state.phone) || this.state.token.length == 0}
                  btnText={"Получить пароль"}
                  onClick={() => this._register()}
                />
              </div>
            ) : (
              <div className="login-form-pass">
                <UiTextInput
                  type="password"
                  placeholder="Введите СМС пароль"
                  callBack={(_code) => this.setState({ code: _code })}
                  warnText={this.state.error2 ? "Неверный смс код" : ""}
                />
                <UiBtnLight
                  btnText="Выслать пароль повторно"
                  disabled={this.state.passTimer > 0}
                  onClick={() => this._register()}
                />
                <p>
                  {this.state.passTimer > 0 ? (
                    <span>
                      Повторный пароль через{" "}
                      {this.state.passTimer >= 10
                        ? "00:" + this.state.passTimer
                        : "00:0" + this.state.passTimer}
                    </span>
                  ) : null}
                </p>
                <div className="login-form-pass-btn">
                  <UiBtnPink
                    btnText="Войти"
                    loaderActive={this.state.loader}
                    onClick={() => this._login()}
                  />
                </div>

                <p className="login-form-agree-text">
                  Нажимая на «Войти» вы подтверждаете согласие с{" "}
                  <a href="#" target="_blank">
                    Политикой конфиденциальности и обработкой персональных
                    данных
                  </a>
                </p>
              </div>
            )}

           

          </div>
        </div>
      </div>
    );

    return (
      <UiModal
        modal="login"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}
export default UiModalLogin;
