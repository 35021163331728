import React, { cloneElement, Component } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";

import "./UiModal.scss";
import "./UiModalDelivery.scss";
import "rc-time-picker/assets/index.css";

import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-zmdi/close";

import UiBtnPink from "../buttons/UiBtnPink";
import UiBtnLight from "../buttons/UiBtnLight";
import UiCheckList from "../forms/UiCheckList";
import UiModal from "./UiModal";
import UiSelect from "../forms/UiSelect";
import {
  getDayMonthName,
  getNonFormatedDateWithOffset,
} from "../../components/common/Dates";

const now = moment().hour(14).minute(30);

class UiModalDelivery extends Component {
  state = {
    paymentType: 0,
    deliveryType: 0,
    selectDeliveryTime: "09:00 - 12:00",
    selectDeliveryExactTime: now,
    selectDeliveryTimeValue: 0,
    deliveries: [
      {
        value: 0,
        label: "Доставка в 3-х часовой интервал - 300 ₽",
        price: 300,
      },
      { value: 1, label: "Доставка в часовой интервал - 500 ₽", price: 500 },
      { value: 2, label: "Доставка к точному времени - 1000 ₽", price: 1000 },
      { value: 4, label: "Дополнительные интервалы", price: 0 },
    ],
  };

  constructor(props) {
    super(props);
  }

  _getPrice(_key) {
    let p = 0;
    this.state.deliveries.map((item) => {
      if (item.value == _key) p = item.price;
    });
    return p;
  }

  disabledHours() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23];
  }

  render() {
    const modalInner = (
      <div className="modal-delivery">
        <div className="modal-body">
          <button
            className="modal-close-btn"
            onClick={() => this.props.modalClose()}
          >
            <Icon className="modal-close-btn-icon" icon={closeIcon} />
          </button>
          <div className="modal-delivery-wrap">
            <h3>Изменить тип доставки</h3>
            <div className="delivery-type-wrap">
              <UiCheckList
                callBack={(value) => this.setState({ deliveryType: value })}
                checkList={this.state.deliveries}
              />
              {this.state.deliveryType == 4 ? (
                <div className="delivery-type-info">
                  <UiSelect
                    callBack={(value) =>
                      this.setState({
                        selectDeliveryTimeValue: value.value,
                        selectDeliveryTime: value.option,
                      })
                    }
                    selectItems={[
                      { value: 0, option: "09:00 - 00:00" },
                      { value: 300, option: "21:00 - 22:00" },
                      { value: 300, option: "22:00 - 23:00" },
                      { value: 300, option: "23:00 - 00:00" },
                    ]}
                    selectedText={this.state.selectDeliveryTime}
                  />
                  <p>
                    Дополнительные интервалы в нерабочие часы мастерской,
                    возможна только <span>в пределах МКАД</span> и по{" "}
                    <span>100% предоплате</span>.
                  </p>
                  <p>
                    Заказ необходимо оформить <span>до 19:00</span> дня
                    доставки.
                  </p>
                </div>
              ) : this.state.deliveryType == 2 ? (
                <div className="delivery-type-info">
                  <div className="delivery-date-time-select">
                    <DatePicker
                      value={this.state.selectDeliveryExactDate}
                      minDate={new Date()}
                      maxDate={getNonFormatedDateWithOffset(5)}
                      onChange={(e) =>
                        this.setState({ selectDeliveryExactDate: e })
                      }
                    />

                    <TimePicker
                      style={{ width: 100 }}
                      hourStep={1}
                      minuteStep={30}
                      showSecond={false}
                      disabledHours={this.disabledHours}
                      value={this.state.selectDeliveryExactTime}
                      onChange={(value) =>
                        this.setState({
                          selectDeliveryExactTime: value && value,
                        })
                      }
                    />
                  </div>
                  <p>Доставка осуществляется в интервал c 9:00 до 21:00</p>
                  <p>
                    При заказе доставки за МКАД, возможна доплата, менеджер
                    уточнит точную стоимость после заказа.
                  </p>
                </div>
              ) : this.state.deliveryType == 1 ? (
                <div className="delivery-type-info">
                  <p>
                    Доставка осуществляется в часовой интервал c 9:00 до 21:00
                  </p>
                  <p>
                    При заказе доставки за МКАД, возможна доплата, менеджер
                    уточнит точную стоимость после заказа.
                  </p>
                </div>
              ) : (
                      <div className="delivery-type-info">
                        <p>
                          Доставка осуществляется в 3-x часовой интервал c 9:00 до
                          21:00
                  </p>
                        <p>
                          При заказе доставки за МКАД, возможна доплата, менеджер
                          уточнит точную стоимость после заказа.
                  </p>
                      </div>
                    )}
            </div>
            <div className="modal-delivery-control">
              <div className="modal-order-control-left">
                <UiBtnLight
                  btnText="Отменить"
                  onClick={() => this.props.modalClose()}
                />
              </div>
              <UiBtnPink
                btnText="Подтвердить"
                onClick={() => {
                  if (this.state.deliveryType == 2) {
                    this.props.callBack({
                      text:
                        "Доставка к " +
                        getDayMonthName(this.state.selectDeliveryExactDate) +
                        " " +
                        this.state.selectDeliveryExactTime.format("HH:mm"),
                      value: this.state.deliveryType,
                      price: this._getPrice(this.state.deliveryType),
                      date: this.state.selectDeliveryExactDate,
                      time: this.state.selectDeliveryExactTime,
                      type: 2,
                    });
                  } else if (this.state.deliveryType == 0) {
                    this.props.callBack({
                      text: "Доставка в 3-х часовой интервал",
                      value: this.state.deliveryType,
                      price: this._getPrice(this.state.deliveryType),
                      date: new Date(),
                      time: null,
                      type: 0,
                    });
                  } else if (this.state.deliveryType == 1) {
                    this.props.callBack({
                      text: "Доставка в часовой интервал",
                      value: this.state.deliveryType,
                      price: this._getPrice(this.state.deliveryType),
                      date: new Date(),
                      time: null,
                      type: 1,
                    });
                  } else if (this.state.deliveryType == 4) {
                    this.props.callBack({
                      text: "Дополнительные интервалы",
                      value: this.state.deliveryType,
                      price: this.state.selectDeliveryTimeValue,
                      date: new Date(),
                      time: this.state.selectDeliveryTime,
                      type: 4,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <UiModal
        modal="delivery"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}
export default UiModalDelivery;
