import {getEnvOfStorage} from "./EnvContext";

export const GetFlowersById = (_id) => {

  return fetch(getEnvOfStorage()?.apiUrl + '/flower/' + _id, {
      method: 'GET',
  }).then(function (response) {
      return response.json();
  });
}

export const GetFlowersByCategory = (_id) => {

    return fetch(getEnvOfStorage()?.apiUrl + '/flowers/category/' + _id, {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
}

export const GetSubCategories = () => {

    return fetch(getEnvOfStorage()?.apiUrl + '/categories/sub/all', {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
}

export const GetCartRecommendation = () => {

    return fetch(getEnvOfStorage()?.apiUrl + '/flowers/recommendation/cart', {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
}

export const getAllGroupedChapters = () => {
    return fetch(getEnvOfStorage()?.apiUrl + '/chapters/get/grouped/all', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      } 
    }).then((response) => {
      return response.json()
    });
  }
  
  
  
  export const getAllChapters = (_token) => {
    return fetch(getEnvOfStorage()?.apiUrl + '/chapters/all', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      } 
    }).then((response) => {
      return response.json()
    });
  }



  
  export const getTags = () => {

    return fetch(getEnvOfStorage()?.apiUrl + '/flowers/tags/all', {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
  }
  