import React, { Component } from "react";

import "./UiBtnLight.scss";

class UiBtnLight extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <div>
        {this.props.href ? (
          <a
            className="btn btn-light"
            href={this.props.href}
            disabled={this.props.disabled}
          >
            <span>{this.props.btnText}</span>
          </a>
        ) : (
          <button
            className="btn btn-light"
            onClick={this.props.onClick}
            disabled={this.props.disabled}
          >
            <span>{this.props.btnText}</span>
          </button>
        )}
      </div>
    );
  }
}
export default UiBtnLight;
