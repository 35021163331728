import React, { Component } from "react";

import "./Gifts.scss";

import demoImage from "../../assets/images/demo/gift-10.jpg";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiCatalogFilter from "../../components/catalog/UiCatalogFilter";
import UiCatalogCard from "../../components/cards/UiCatalogCard";
import UiCategoryCard from "../../components/cards/UiCategoryCard";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiLoader from "../../components/modals/UiLoader";
import UiModalCatalog from "../../components/modals/UiModalCatalog";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiPageTitle from "../../components/header/UiPageTitle";

import { Helmet } from "react-helmet";

import { storeData, retrieveData, getBasketNum } from "../../services/Storage";
import { getSettings } from "../../services/Settings";
import { GetFlowersByCategory, GetSubCategories, getAllGroupedChapters } from "../../services/Flowers";
import Env from "../../services/Env";
import {getEnvOfStorage} from "../../services/EnvContext";

class GiftsPage extends Component {
  state = {
    basketNum: 0,
    pageTitle: "Подарки к букетам",
    filterNum: null,
    showTopBtn: false,
    scrollHeader: false,
    smallCategoryCard: false,
    loader: false,
    user: null,


    settings: {},
    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },
    cart: [],
    giftsList: [],
    currentGiftsList: [],
    subCategoriesList: [],

    allChaptersList: [],
    currentCategory: [],
    subsectionList: [],
    categoryList: [],
    subcategoryList: [],
    pathList: [],

    /* modals */
    modalCatalogActive: false,
    modalLoginActive: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });

    window.addEventListener("beforeunload", this._confirm);
    window.history.pushState(null, "", "/gifts");
    window.onpopstate = this._backConfirm;
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this._confirm);
    window.onpopstate = () => { }
  }

  _backConfirm = async () => {

    if (this.state.pathList.length > 1) {
      let _path = this.state.pathList;
      let _index = _path.length - 1;
      let _items = [];
      let id = _path[_index - 1].value;

      _path = _path.slice(0, _index);
      this.state.allChaptersList.map((item) => {
        if (item.id == id) _items = item.items;
      })

      //_result.length == 0 ? [_items] : _result,
      this.setState({
        pageTitle: _path[_path.length - 1].label,
        currentCategory: this._getGroupedChar(this.state.allChaptersList, id),
        currentGiftsList: this._getItems(_items),
        pathList: _path,
      })
    } else {
      window.location.reload(false);
    }
  }

  _load() {
    this.setState({ loader: true });

    let _cart = retrieveData("cart");
    let _user = retrieveData("mfUser");
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: getBasketNum(_cart) });
    }
    if (_user) {
      this.setState({ user: _user });
    }
    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });


    GetFlowersByCategory(3).then((res) => {
      this.setState({ giftsList: res.response }, () => { 
        this._openFlower(res.response, this.props.match.params)
      });
    });

 
    getAllGroupedChapters().then((res) => {
      //console.log(res);
      if (res.response) {


        let _subsectionList = [];
        let _categoryList = [];
        let _subcategoryList = [];
        res.response.map((item) => {
          if (item.type == 0) _subsectionList.push(item);
          if (item.type == 1) _categoryList.push(item);
          if (item.type == 2) _subcategoryList.push(item);
        })

        this.setState({
          allChaptersList: res.response,
          currentCategory: _subsectionList,
          subsectionList: _subsectionList,
          categoryList: _categoryList,
          subcategoryList: _subcategoryList,
          loader: false
        }, () => { 
          this._openFlower(res.response, this.props.match.params)
        });
      }
    });

  }

  _getGroupedChar(arr, _id) {
    let _arr = [];
    arr.map((item) => {
      if (
        item.parent_chapter == _id  ) _arr.push(item);
    });

    return _arr;
  }

  _getItemsFromGrouped(arr) {
    let _arr = [];

    arr.map((item) => {
      item.items.map((item2) => {
        _arr.push(item2.flower);
      })
    });
    //console.log(_arr);
    return _arr;
  }

  _getItems(arr) {
    let _arr = [];
    arr.map((item2) => {
      if(item2.flower) if(item2.flower.price) _arr.push(item2.flower);
    })

    return _arr;
  }

  _openFlowerItem(arr, params) {
    console.log(arr, params)
     if (params.gift) {
       arr.map((item) => {
         if (item.imgs &&  ( item.id == params.gift || item.title_url == params.gift ) ) {
         
           this.setState({
             modalCatalogActive: true,
             selectedItem: item,
           });
         }
       })
     } 
  }


  _openFlower(arr, params) { 
    this._openFlowerItem(arr, params);
    if(params.category && !params.gift ){ 
      this.state.currentCategory.map((item) => {
        if ( item.title_url == params.category  ) {
          let _path = this.state.pathList;
          _path.push({
            label: item.title,
            url: item.title_url,
            value: item.id
          });
          this.props.history.push(`/gifts/${item.title_url}`);
          let _result = this._getGroupedChar(this.state.allChaptersList, item.id);
          if (_result.length > 0) {
            _result.unshift({
              id: new Date().getUTCMilliseconds(),
              image: null,
              is_block: 0,
              is_del: 0,
              items: [],
              parent_chapter: 0,
              title: "Все",
              type: 1,
              is_red: true
            })
          }
          this.setState({
            smallCategoryCard: true,
            pageTitle: item.title,
            currentCategory: _result.length == 0 ? [item] : _result,
            currentGiftsList: [],
            pathList: _path,
          }, () => {
            window.scrollTo(0, 0);
          });

        }
      })
    } else if(params.category && !params.subcategory && params.gift  ){
      this.state.currentCategory.map((item) => {
        if ( item.title_url == params.category  ) {
          let _path = this.state.pathList;
          _path.push({
            label: item.title,
            url: item.title_url,
            value: item.id
          });
          this.props.history.push(`/gifts/${item.title_url}`);
          let _result = this._getGroupedChar(this.state.allChaptersList, item.id);
          if (_result.length > 0) {
            _result.unshift({
              id: new Date().getUTCMilliseconds(),
              image: null,
              is_block: 0,
              is_del: 0,
              items: [],
              parent_chapter: 0,
              title: "Все",
              type: 1,
              is_red: true
            })
          }
          let showSubCategoryTwo = false;
          _result.map((item2) => {
            if ( item2.title_url == params.gift  ) {
              let _path = this.state.pathList;
              this.props.history.push(`/gifts/${_path[_path.length - 1].url}/${item2.title_url}`);
              _path.push({
                label: item2.title,
                url: item.title_url,
                value: item2.id
              });
              showSubCategoryTwo = true;
              let _result2 = this._getGroupedChar(this.state.allChaptersList, item2.id);
              if (_result2.length > 0) {
                _result2.unshift({
                  id: new Date().getUTCMilliseconds(),
                  image: null,
                  is_block: 0,
                  is_del: 0,
                  items: [],
                  parent_chapter: 0,
                  title: "Все",
                  type: 1,
                  is_red: true
                })
              }
              this.setState({
                smallCategoryCard: true,
                pageTitle: item2.title,
                currentCategory: _result2.length == 0 ? [item] : _result2,
                currentGiftsList: [],
                pathList: _path,
              }, () => {
                window.scrollTo(0, 0);
              });
            }
          })

          if(!showSubCategoryTwo) { 
            let url = [];
            this.state.pathList.map((item) => {
              if(item.url) url.push(item.url);
            })
            this.props.history.push(`/gifts/${url.join("/")}/${params.gift}`);
            this._openFlowerItem(arr, params);
          }

        }
      })
    } else if(params.category && params.subcategory &&  params.gift ){
      this.state.currentCategory.map((item) => {
        if ( item.title_url == params.category  ) {
          let _path = this.state.pathList;
          _path.push({
            label: item.title,
            url: item.title_url,
            value: item.id
          });
          this.props.history.push(`/gifts/${item.title_url}`);
          let currentCategory = [];
          let _result = this._getGroupedChar(this.state.allChaptersList, item.id);
          if (_result.length > 0) {
            _result.unshift({
              id: new Date().getUTCMilliseconds(),
              image: null,
              is_block: 0,
              is_del: 0,
              items: [],
              parent_chapter: 0,
              title: "Все",
              type: 1,
              is_red: true
            })
          }
          _result.map((item2) => {
            if ( item2.title_url == params.subcategory  ) {
           
              this.props.history.push(`/gifts/${_path[_path.length - 1].url}/${item2.title_url}`);
              _path.push({
                label: item2.title,
                url: item2.title_url,
                value: item2.id
              });
              let _result2 = this._getGroupedChar(this.state.allChaptersList, item2.id);
              if (_result2.length > 0) {
                _result2.unshift({
                  id: new Date().getUTCMilliseconds(),
                  image: null,
                  is_block: 0,
                  is_del: 0,
                  items: [],
                  parent_chapter: 0,
                  title: "Все",
                  type: 1,
                  is_red: true
                })
              }
              currentCategory = _result2;
              this.setState({
                smallCategoryCard: true,
                pageTitle: item2.title,
                currentCategory: _result2.length == 0 ? [item2] : _result2,
                currentGiftsList: [],
                pathList: _path,
              }, () => {
                window.scrollTo(0, 0);
              });
            }
          })
          let showSubCategoryTwo = false;
          currentCategory.map((item2) => {
            if ( item2.title_url == params.gift  ) {
             
              this.props.history.push(`/gifts/${_path[_path.length - 2].url}/${_path[_path.length - 1].url}/${item2.title_url}`);
              _path.push({
                label: item2.title,
                url: item2.title_url,
                value: item2.id
              });
              showSubCategoryTwo = true;
              this.setState({
                smallCategoryCard: true,
                pageTitle: item2.title,
                currentCategory: [item2],
                currentGiftsList: [],
                pathList: _path,
              }, () => {
                window.scrollTo(0, 0);
              });

            }
          })
          if(!showSubCategoryTwo) { 
            let url = [];
            this.state.pathList.map((item) => {
              if(item.url) url.push(item.url);
            })
            this.props.history.push(`/gifts/${url.join("/")}/${params.gift}`);
            this._openFlowerItem(arr, params);
          }

        }
      })
    }
  }

  hasInCart(id) {
    let fl = false;
    this.state.cart.map((item) => {
      if (id == item.id) fl = true;
    });
    return fl;
  }

  _getBasketNum(_cart) {
    let num = 0;
    _cart.map((item) => {
      num = num + item.count;
    });
    return num;
  }

  _toCart(item) {
    let arr = this.state.cart;
    let _item = item;
    _item.count = 1;
    _item.color = 0;
    _item.giftbox = false;
    if (this.hasInCart(_item.id)) {
      let index = 0;
      arr.map((arrItem, arrIndex) => {
        if (arrItem == item.id) index = arrIndex;
      });
      arr[index].count = arr[index].count + 1;
    } else {
      arr = arr.concat(_item);
    }
    return arr;
  }

  addToCart(_cart) {
    this.setState({
      basketNum: this._getBasketNum(_cart),
      cart: _cart,
      infoActive: true,
    });
    storeData("cart", _cart);
  }


  render() {
    var gifts = this.state.currentCategory.map((item2, index2) => {
   
      if (item2.title) {
        let _arr = [];
        item2.items.map((item, index) => {
          if (item.flower) {
            _arr.push(item);
          }
        })
        let subGifts = _arr.sort(function (a, b) { return a.flower.price - b.flower.price; }).map((item,index) => {
         
          return (
            <UiCatalogCard
              key={index}
              imageList={item.flower.imgs}
              title={item.flower.title}
              price={"₽ " + item.flower.price}
              shopClick={() => {
                this.setState({ selectedItem: item.flower });
                this.addToCart(this._toCart(item.flower));
              }}
              openClick={() => { 
                let url = [];
                this.state.pathList.map((item) => {
                  if(item.url) url.push(item.url);
                })
                this.props.history.push(`/gifts/${url.join("/")}/${item.flower.title_url}`);
                this._openFlower(this.state.giftsList, {gift: item.flower.title_url})
              }}
              category="0"
              filter={item.flower.type}
              squareCard={true}
            />
          );
        })

        if (subGifts.length > 0) {
          return (
            <div key={index2} className="gifts-catalog-container">
              {this.state.currentCategory.length > 1 ? <h3>{item2.title}</h3> : null}
              {subGifts}
            </div>
          )
        }

      }

    });

    var subCategory = this.state.currentGiftsList.sort(function (a, b) { return a.price - b.price; }).map((item, index) => {
      if (item.title) {
          return (
            <UiCatalogCard
              key={index}
              imageList={item.imgs}
              title={item.title}
              price={"₽ " + item.price}
              shopClick={() => {
                this.setState({ selectedItem: item });
                this.addToCart(this._toCart(item));
              }}
              openClick={() => {
                this.props.history.push(`/gifts/${item.title_url}`);
                this._openFlower(this.state.giftsList, {gift: item.title_url})
              }}
              category="0"
              filter={item.type}
              squareCard={true}
            />
          );
      }
    });

    var subsection = this.state.currentCategory.map((item, index) => {
      if (this.state.currentCategory.length > 1) {
        return (
          <UiCategoryCard
            key={index}
            image={item.image ? getEnvOfStorage()?.uploadsUrl + item.image.split(",")[0] : demoImage}
            title={item.title}
            text={item.description ? item.description : null}
            onClick={() => { 
              let _path = this.state.pathList;
              if (!item.is_red) {
                if (item.type == 0) {
              
                  _path.push({
                    label: item.title,
                    url: item.title_url,
                    value: item.id
                  });
                  this.props.history.push(`/gifts/${item.title_url}`);

                  let _result = this._getGroupedChar(this.state.allChaptersList, item.id);

                  if (_result.length > 0) {
                    _result.unshift({
                      id: new Date().getUTCMilliseconds(),
                      image: null,
                      is_block: 0,
                      is_del: 0,
                      items: [],
                      parent_chapter: 0,
                      title: "Все",
                      type: 1,
                      is_red: true
                    })
                  }

                  this.setState({
                    smallCategoryCard: true,
                    pageTitle: item.title,
                    currentCategory: _result.length == 0 ? [item] : _result,
                    currentGiftsList: [],
                    pathList: _path,
                  }, () => {
                    window.scrollTo(0, 0);
                  });
                }
                if (item.type == 1) {
                  this.props.history.push(`/gifts/${_path[_path.length - 1].url}/${item.title_url}`);
                  _path.push({
                    label: item.title,
                    url: item.title_url,
                    value: item.id
                  });
                 
                  let _result = this._getGroupedChar(this.state.allChaptersList, item.id);
         
                  if (_result.length > 0) {
                    _result.unshift({
                      id: new Date().getUTCMilliseconds(),
                      image: null,
                      is_block: 0,
                      is_del: 0,
                      items: [],
                      parent_chapter: 0,
                      title: "Все",
                      type: 1,
                      is_red: true
                    })
                  }

                  this.setState({
                    smallCategoryCard: true,
                    pageTitle: item.title,
                    currentCategory: _result.length == 0 ? [item] : _result,
                    currentGiftsList: [],
                    pathList: _path,
                  }, () => {
                    window.scrollTo(0, 0);
                  });
                }
                if (item.type == 2) { 
          
                  this.props.history.push(`/gifts/${_path[_path.length - 2].url}/${_path[_path.length - 1].url}/${item.title_url}`);
                  _path.push({
                    label: item.title,
                    url: item.title_url,
                    value: item.id
                  });
                 
                  this.setState({
                    smallCategoryCard: true,
                    pageTitle: item.title,
                    currentCategory: [item],
                    currentGiftsList: [],
                    pathList: _path,
                  }, () => {
                    window.scrollTo(0, 0);
                  });
                }
              }
            }}
            red={item.is_red}
            resized={this.state.smallCategoryCard}
          />
        );
      }


    });

    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>Подарки к букетам от MATE flowers</title>
          <meta name="description" content="К каждому букету вы можете приобрести подарок из нашей коллекции" />
          <meta name="keywords" content="Доставка подарков москва, купить цветы и подарки москва" />
        </Helmet>
        <div id="wrapper" className="page-wrapper">
          {/* gifts header */}
          <section className="section gifts-header">
            <UiHeader
              callBack={(value) => { }}
              page="gifts"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>

          <UiCatalogFilter
            page={"gifts"}
          />

          {/* page title */}
          <section className="section gifts-title">
            <UiPageTitle
              backImg={require("../../assets/images/header/bg-breacrum.jpg")}
              pageLink="/gifts"
              pageName="Подарки"
              title={this.state.pageTitle}

              list={this.state.pathList}
              onClick={(id) => {
                let _index = 0;
                let _items = [];
                let _path = this.state.pathList;
                _path.map((item, index) => {
                  if (item.value == id) _index = index;
                })
                _path = _path.slice(0, _index + 1);
                this.state.allChaptersList.map((item) => {
                  if (item.id == id) _items = item.items;
                })
            

                let _result = this._getGroupedChar(this.state.allChaptersList, id);
                if (_result.length > 0) {
                  _result.unshift({
                    id: new Date().getUTCMilliseconds(),
                    image: null,
                    is_block: 0,
                    is_del: 0,
                    items: [],
                    parent_chapter: 0,
                    title: "Все",
                    type: 1,
                    is_red: true
                  })
                }

                let url = [];
                _path.map((item) => {
                  if(item.url) url.push(item.url);
                })
                this.props.history.push(`/gifts/${url.join("/")}`);

            
                this.setState({
                  pageTitle: _path[_path.length - 1].label,
                  currentCategory: _result.length == 0 ? [{ title: "1", items : _items }] : _result,
                  currentGiftsList: this._getItems(_items),
                  pathList: _path,
                }, () => {
                  window.scrollTo(0, 0);
                });

              }}
            />
          </section>
          {/* gifts catalog */}
          <section className="section gifts-catalog">
            <div className="wrapper">
              {/*
                <div className="catalog-filter">
                  <div className="filter-type">
                    <button
                      className={this.state.filterNum == null ? "active" : ""}
                      onClick={() => {
                        this.setState({ filterNum: null });
                      }}
                    >
                      ВСЕ ТОВАРЫ
                    </button>
                    {tabs}
                  </div>
                </div>
              */}


              <div className="gifts-catalog-categories">
                {subsection}
              </div>

              {this.state.pathList.length > 0 ? gifts : null}


            </div>
          </section>
          {/* gifts footer */}
          <section className="section gifts-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiBtnBasket
          cartNum={this.state.basketNum}
          infoActive={this.state.infoActive}
          callBack={() => this.setState({ infoActive: false })}
        />

        <UiLoader modalOpen={this.state.loader} />

        {/* modals */}
        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.props.history.push("/profile");
          }}
        />

        <UiModalCatalog
          modalOpen={this.state.modalCatalogActive}
          modalClose={() => {
            let url = [];
            this.state.pathList.map((item) => {
              if(item.url) url.push(item.url);
            })
            this.props.history.push(`/gifts/${url.join("/")}`);
            this.setState({ modalCatalogActive: false });
          }}
          imageList={this.state.selectedItem.imgs}
          item={this.state.selectedItem}
          title={this.state.selectedItem.title}
          filter={this.state.selectedItem.type}
          description={this.state.selectedItem.description}
          composition={this.state.selectedItem.composition}
          giftsList={[]}
          colors={
            this.state.selectedItem.modifications != null
              ? this.state.selectedItem.modifications
                .split(",")
                .filter((elem, index, self) => {
                  return index === self.indexOf(elem);
                })
              : []
          }
          price={this.state.selectedItem.price}
          cart={this.state.cart}
          settings={this.state.settings}
          onClick={(cart) => {
            this.addToCart(this._toCart(cart));
            this.setState({ modalCatalogActive: false });
          }}
        />
      </div>
    );
  }
}

export default GiftsPage;
