import React, { Component } from "react";
import ReactDOM from "react-dom";

import { Icon } from "@iconify/react";
import favoriteIcon from '@iconify-icons/zmdi/favorite';

import "./About.scss";

import aboutImage from "../../assets/images/about/about-image-company.jpg";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiPageTitle from "../../components/header/UiPageTitle";

import { Helmet } from "react-helmet";

import { GetFlowersByCategory } from "../../services/Flowers";
import { getSettings } from "../../services/Settings";
import { storeData, retrieveData, getBasketNum } from "../../services/Storage";

class AboutPage extends Component {
  state = {
    showTopBtn: false,
    scrollHeader: false,
    basketNum: 0,
    user: null,

    settings: {},

    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },

    cart: [],
    mainList: [],
    giftsList: [],
    waifuList: [],
    manList: [],

    /* modals */
    modalLoginActive: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });
  }

  _load() {
    let _cart = retrieveData("cart");
    let _user = retrieveData("mfUser");
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: getBasketNum(_cart) });
    }
    if (_user) {
      this.setState({ user: _user });
    }
    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });

    GetFlowersByCategory(0).then((res) => {
      this.setState({ mainList: res.response });
      console.log(res);
    });
    GetFlowersByCategory(1).then((res) => {
      this.setState({ waifuList: res.response });
    });
    GetFlowersByCategory(2).then((res) => {
      this.setState({ manList: res.response });
    });
    GetFlowersByCategory(3).then((res) => {
      this.setState({ giftsList: res.response });
    });
  }

  addToCart(_cart) {
    this.setState({ basketNum: getBasketNum(_cart) });
    storeData("cart", _cart);
  }

  render() {
    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>О компании MATE flowers</title>
          <meta name="description" content="Наши преимущества, миссия и цели" />
          <meta name="keywords" content="Доставка букетов Москва, купить цветы Москва" />
        </Helmet>
        <div className="page-wrapper">
          {/* about header */}
          <section className="section about-header">
            <UiHeader
              callBack={(value) => { }}
              page="about"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>
          {/* page title */}
          <section className="section about-title">
            <UiPageTitle
              backImg={require("../../assets/images/about/D98A1759_1x.jpg")}
              pageLink="/about"
              pageName="О нас"
              title="MATE flowers"
            />
          </section>
          {/* about catalog */}
          <section className="section about">
            <div className="wrapper">
              <div className="about-wrap">
                <div className="about-info">
                  <img src={aboutImage} />
                </div>
                <div className="about-info">
                  <p>
                    Мастерская авторских букетов от 1650 руб. и подарков от 300
                    руб., с актуальным каталогом на сайте и доставкой от 120
                    минут .
                  </p>
                  <h3>ЧЕМ БУДЕМ ПОЛЕЗНЫ?)</h3>
                  <p>
                    Соберем букет и бесплатно приложим открытку с вашими
                    пожеланиями, подкормку для цветов, инструкцию по уходу,
                    поставим на воду и в коробку для удобства транспортировки и
                    сохранности.
                  </p>
                  <p>
                    Дополнительно предложим каталог крафтовых подарков от
                    небольших производителей, которые прекрасно дополнят наши
                    букеты и принесут еще больше радости вашим родным и близким
                  </p>
                  <h3>МЫ ЗА ВЫСОКИЙ СЕРВИС И ТЕХНОЛОГИИ</h3>
                  <ul>
                    <li>
                      Удобный сайт для оформления заказа не более чем за 1 минуту
                    </li>
                    <li>
                      Точное соответствие заказа, фотографиям на нашем сайте;
                    </li>
                    <li>
                      Менеджеры на связи с 9 до 21 в Whatsapp или Instagram. Отвечают на сообщения, в порядке очереди, но не дольше 1-2 минут;
                    </li>
                    <li>
                      Двойная проверка на свежесть и качества цветов: при приеме поставки и перед отправкой букета, чтобы точно не подвести вас;
                    </li>
                    <li>
                      Перед отправкой заказа пришлем фото для согласования и только после одобрения, отправим в доставку;
                    </li>
                    <li>
                      Если вы не знаете адрес получателя, позвоним и договоримся о доставке по удобному адресу :)
                    </li>
                  </ul>
                  <h3>НАША ГЛАВНАЯ ЦЕЛЬ И МИССИЯ</h3>
                  <p>
                    Создание лучшего сервиса доставки цветов и подарков с
                    доставкой за 30 минут после оформления заказа.
                  </p>
                  <p>
                    За год работы наш штат вырос с 4 человек, до 25, а
                    количество заказов с 10, до 350 в день.
                  </p>
                  <p>
                    В будущем мы хотим создать сеть цветочных мастерских, с
                    одинаковым уровнем обслуживания, удобным сайтом и
                    приложением, включенными в процесс менеджерами, и быстрой
                    доставкой букетов, в любую точку города.
                  </p>
                  <p>
                    Вы можете оставить свой отзыв о нас по ссылке, которую
                    получите после доставки букета. Мы рады хорошим отзывам и
                    благодаря вашей обратной связи, исправили уже много
                    недочетов.
                  </p>
                  <br />
                  <p>
                    С самого начала проекта мы открыто рассказываем о своих
                    успехах, промахах и работе над ошибками.
                  </p>
                  <p>
                    Больше о жизни мастерской можно узнать из нашего Telegram
                    канала и Instagram.
                  </p>
                  <div className="about-item">
                    <div className="about-item-icon">
                      <Icon className="about-icon" icon={favoriteIcon} />
                    </div>
                    <p>МЫ ЛЮБИМ НАШИХ КЛИЕНТОВ!</p>
                  </div>
                </div>
                {/*<div className="about-image">
                  <img src={aboutImage} />
    </div>*/}
              </div>
            </div>
          </section>

          {/* about footer */}
          <section className="section classes-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiBtnBasket
          cartNum={this.state.basketNum}
          infoActive={this.state.infoActive}
          callBack={() => this.setState({ infoActive: false })}
        />


        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.props.history.push("/profile");
          }}
        />
      </div>
    );
  }
}

export default AboutPage;
