import React, { Component } from 'react';

import './UiTextInputButton.scss';

class UiTextInputButton extends Component {

  state = {
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="form-group input-button">
        <label>{this.props.label}</label>
        <input
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          type={this.props.type}
          value={this.props.value}
          className={"text-input" + (this.props.btnText ? " text-right" : "")}
          onChange={e => this.props.callBack(e.target.value)}
        />
        {this.props.btnText ?
          <button className="btn" onClick={this.props.onClick}>{this.props.btnText}</button>
          : null
        }
      </div>
    );
  }
}
export default UiTextInputButton;