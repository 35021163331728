import React, { Component } from "react";

import "./UiCatalogCard.scss";

import { Icon } from "@iconify/react";
import shoppingCart from "@iconify/icons-zmdi/shopping-cart";
import arrowRight from "@iconify/icons-zmdi/arrow-right";

import flowerBg from "../../assets/images/catalog/flower-bouquet.svg";
import demo from "../../assets/images/demo/blur.png"
import { getEnvOfStorage } from "../../services/EnvContext";

class UiCatalogCard extends Component {
  state = {
    activeSlide: 0,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  _convertImages(_img) {
    let arr = [];
    let resArr = [];
    if (_img != null) arr = _img.split(",");
    arr.map((item, index) => {
      if (index == 0) {
        resArr.push({
          value: index,
          alt: "Букет",
          src: getEnvOfStorage()?.uploadsUrl + item.replace(".jpg", "-min.jpg"),
        });
      }

    });
    return resArr;
  }

  _nextSlide() {
    if (
      this._convertImages(this.props.imageList).length >
      this.state.activeSlide + 1
    ) {
      this.setState({ activeSlide: this.state.activeSlide + 1 });
    } else {
      this.setState({ activeSlide: 0 });
    }
  }

  _prevSlide() {
    if (this.state.activeSlide - 1 > 0) {
      this.setState({ activeSlide: this.state.activeSlide - 1 });
    } else {
      this.setState({ activeSlide: 0 });
    }
  }

  getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    );
  }

  handleTouchStart(evt) {
    const firstTouch = this.getTouches(evt)[0];
    this.setState({ xDown: firstTouch.clientX, yDown: firstTouch.clientY });
  }

  handleTouchMove(evt) {
    if (!this.state.xDown || !this.state.yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this.state.xDown - xUp;
    var yDiff = this.state.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        this._nextSlide();
      } else {
        /* right swipe */
        this._prevSlide();
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this.setState({ xDown: null, yDown: null });
  }

  render() {
    var slideImageList = this._convertImages(this.props.imageList).map(
      (item, index) => {
        return (
          <div
            className={
              "catalog-image-slide" +
              (index == this.state.activeSlide ? " active" : "")
            }
            key={index}
          >
            <img src={item.src} alt={item.alt} />
          </div>
        );
      }
    );

    var controlsList = this._convertImages(this.props.imageList).map(
      (item, index) => {
        return (
          <button
            className={
              "slide-btn" + (index == this.state.activeSlide ? " active" : "")
            }
            key={index}
          ></button>
        );
      }
    );

    return (
      <div className={"catalog-card" + (this.props.squareCard ? " catalog-card-sq" : "")} style={{ width: this.props.width }}>
        <div
          className="catalog-card-image"
          style={{ backgroundImage: "url(" + { flowerBg } + ")" }}
          onTouchStart={(ev) => this.handleTouchStart(ev)}
          onTouchMove={(ev) => this.handleTouchMove(ev)}
        >
          <div className="catalog-card-slider" onClick={this.props.openClick}>
            {
              slideImageList.length > 0 ?
                slideImageList :
                <div className={"catalog-image-slide  active"}>
                  <img src={demo} />
                </div>
            }
            <div className="catalog-slider-controls">{controlsList}</div>
          </div>
          <div className="catalog-card-control">
            {this._convertImages(this.props.imageList).length == 1 ? null : (
              <button onClick={() => this._nextSlide()}>
                <Icon icon={arrowRight} />
              </button>
            )}
          </div>

          {this.props.filter && this.props.filter != 0 ? (
            <div className="filter-label">
              <span>
                {this.props.filter == 1
                  ? "Новинка"
                  : this.props.filter == 2
                    ? "Limited"
                    : this.props.filter == 3
                      ? "Лидер продаж"
                      : null}
              </span>
            </div>
          ) : null }
        </div>
        <div className="catalog-card-info" onClick={this.props.shopClick}>
          <h3>{this.props.title}</h3>
          <div className="catalog-card-info-price">
            <p>
              {this.props.price}{" "}
            </p>
            {this.props.category != 1 ? (
              <button>
                <Icon color="#fff" icon={shoppingCart} />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default UiCatalogCard;
