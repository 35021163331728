import React, { useEffect, useState } from "react";
import GiftSvg from "../../Svg/HeatFillSvg";
import './index.css';
import { useSelector } from "react-redux";
import { selectLoyalty } from "../../Store/Slices/LoyaltySlice";
import StartOff from "../../Svg/StarOff";
import Sale from "../../Svg/SaleSvg";
import UiTextInput from "../forms/UiTextInput";
import UiTextInputButton from "../forms/UiTextInputButton";

/**
 *
 * @param onBonusChange
 * @returns {JSX.Element}
 * @constructor
 */
const UiAvailableBonuses = ({ disabled, summ, totalDiscount, maxDiscount, settings, onSendPromoCode, onBonusChange }) => {
    const { totalBonuses, appliedBonuses } = useSelector(selectLoyalty);
    const [value, setValue] = useState(0);
    const [promoCode, setPromoCode] = useState(null);

    useEffect(() => {
        onBonusChange(value);
    }, [value]);

    useEffect(() => {
        setValue(appliedBonuses);
    }, [appliedBonuses])

    useEffect(() => {
        if(value) onChange(value.toString());
    }, [summ])

    const onChange = (val) => { 
        
        if (val.match(/^\d+$/)) {
            val = Number(parseInt(val, 10));
         
            let discount = Math.round(maxDiscount * ((settings?.settings_max_percent_discount | 15) / 100) );
           
           if (val <= discount) {
                setValue(val);
             }
        } else {
            setValue(0);
        }
    }

    return (
        <div className="columnPanel">
            <div className="bonus-gift-panel">
                <div className="mr-05"><GiftSvg h={20} w={22} /></div>
                <p>Доступно бонусов: <b>{totalBonuses | 0}</b></p>
            </div>
            <div className="bonus-gift-panel">
                <div className="mr-05"><StartOff h={22} w={22} /></div>
                {disabled ?
                    <div className="rowBlock">
                        <span>Не суммируется с промокодом</span>
                    </div>
                    : <div className="rowBlock">
                        <span>Использовать бонусы:</span>
                        <div className="bonusField"> 
                            <UiTextInput
                                value={value.toString()}
                                type="number"
                                min={0}
                                placeholder={0}
                                callBack={onChange} />
                        </div>
                        <a style={{ textDecoration: 'underline' }} onClick={() => {

                            let _dsSum = Math.round(maxDiscount * ((settings?.settings_max_percent_discount | 15) / 100) );
                            if(totalBonuses < _dsSum) _dsSum = totalBonuses;
                            setValue( _dsSum );

                        }}>Списать все</a>
                    </div>}
            </div>

            <div className="bonus-gift-panel">

                <div className="rowBlock promo-bonus-gift-wrap">

                    <div className="bonusField">
                        <UiTextInputButton
                            placeholder="Введите промокод"
                            btnText="Применить"
                            callBack={(val) => setPromoCode(val)}
                            onClick={() => onSendPromoCode(promoCode)}
                        />
                    </div>
                    {totalDiscount > 0 ?
                        <p>Размер скидки: <b>{totalDiscount} ₽ (не более {(settings?.settings_max_percent_discount | 15)}% суммы букетов)</b></p> :  
                    value > 0 ?
                        <p>Размер скидки: <b>{value} ₽ (не более {(settings?.settings_max_percent_discount | 15)}% суммы букетов)</b></p> : null}
                </div>

            </div>



        </div>
    );
}

export default UiAvailableBonuses;