import React from "react";

const EnvContext = React.createContext(null);

const ENV_PARAMS = 'env_params';

/**
 *
 * @param params
 */
export function setEnvToStorage(params) {
    localStorage.setItem(ENV_PARAMS, JSON.stringify(params));
}

/**
 *
 * @returns {string}
 */
export function getEnvOfStorage() {
    return localStorage.getItem(ENV_PARAMS) ? JSON.parse(localStorage.getItem(ENV_PARAMS)) : null;
}

export default EnvContext;