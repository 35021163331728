import React, { Component } from "react";

import "./UiModal.scss";
import "./UiModalPayment.scss";

import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-zmdi/close";

import UiBtnLight from "../buttons/UiBtnLight";
import UiBtnPink from "../buttons/UiBtnPink";
import UiBtnPinkOutline from "../buttons/UiBtnPinkOutline";
import UiCheckList from "../forms/UiCheckList";
import UiModal from "./UiModal";
import UiTextArea from "../forms/UiTextArea";
import UiTextInput from "../forms/UiTextInput";
import UiTextInputSV from "../forms/UiTextInputSV";

class UiModalPayment extends Component {
  state = {
    activeOrderStep: 1,
    paymentType: 0,
    recipientAdd: 1,
    deliveryActive: true,
    deliveryType: 0,
    addressesType: "new",
    addressString: "",
    addressDistance: 0,

    postCardText: "",
    commentText: "",

    errorsMsgList: ["Доставка не выбрана", "Поле адрес не заполнено"],

    selectedDate: new Date(),
    selectedTime: "9:00 - 12:00",
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {

    if (this.props.modalOpen != prevProps.modalOpen) {
      if (this.props.addressesHistory.length > 1) {


        if (
          this.props.addressesHistory[0].type == "old" &&
          this.state.addressString == ""
        ) {
          this.setState({ addressesType: this.props.addressesHistory[0].type });
          this._changeAddress({
            addressString: this.props.addressesHistory[0].label,
            distance: this.props.addressesHistory[0].distance,
          });
        }
      }
    }
  }

  _changeHistoryAddress(value) {
    this.setState({
      adressTypeValue: value,
      addressesType: this.props.addressesHistory[value].type,
    });
    if (this.props.addressesHistory[value].type == "old") {
      this._changeAddress({
        addressString: this.props.addressesHistory[value].label,
        distance: this.props.addressesHistory[value].distance,
      });
    } else {
      //if (this.state.addressString != "") {
      this.props.deliviresDistanceCallBack(4);
      this.setState({ addressString: "", deliveryType: 0 });
      //}
    }
  }

  _changeAddress(res) {
    this.setState({
      addressString: res.addressString,
      addressDistance: res.distance,
    });
    console.log("_changeAddress", res);
    if (res.distance > 0) {
      if (res.distance <= 5) {
        this.props.deliviresDistanceCallBack(0);
      } else if (res.distance > 5 && res.distance <= 10) {
        this.props.deliviresDistanceCallBack(1);
      } else if (res.distance > 10 && res.distance <= 15) {
        this.props.deliviresDistanceCallBack(2);
      } else if (res.distance > 15) {
        this.props.deliviresDistanceCallBack(3);
      }
    } else {
      this.setState({ deliveryTitle: "", deliveryType: 0 }, () => {
        this.props.deliviresDistanceCallBack(4);
      });
    }
  }

  render() {
    const modalInner = (
      <div className="modal-payment">
        <div className="modal-body">
          <button
            className="modal-close-btn"
            onClick={() => this.props.modalClose()}
          >
            <Icon className="modal-close-btn-icon" icon={closeIcon} />
          </button>
          {this.state.activeOrderStep == 1 ? (
            <div className="modal-order-wrap">
            </div>
          ) : null}
        </div>
      </div>
    );

    return (
      <UiModal
        modal="payment"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}
export default UiModalPayment;
