import {getEnvOfStorage} from "./EnvContext";

export const storeData  = (key, value) => {
    let val = JSON.stringify(value);
    return  localStorage.setItem(key, val);
}

export const isJson = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const retrieveData   = (key) => {
    return  isJson(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key) ) : null;
}

/**
 * Подсчитать количество единиц товара в корзине
 * @param {any[]} _cart
 * @returns {number}
 */
export const getBasketNum = (_cart) => {
  let num = 0;
  _cart.map((item) => {
    num = num + item.count;
  });
  return num;
}


export const addFile = (file, _type) => {
  let formData = new FormData();
  formData.append('type', _type);
  formData.append('file', file);

  return fetch(getEnvOfStorage()?.apiUrl + '/files/add', {
    method: 'POST',

    body: formData
  }).then(function (response) {
    return response.json();
  });
}
