import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./UiModal.scss";

class UiModal extends Component {
  state = {
    checkVerticalScroll: false,
    modalVisible: false,
    topScrollConst: 0,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (document.getElementById("modal-inner")) {
      let windowHeight = window.innerHeight;
      let modalHeight = document.getElementById("modal-inner").offsetHeight;

      this.setState({
        checkVerticalScroll: modalHeight + 100 > windowHeight,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalOpen && !this.state.modalVisible) {
      this.setState({ modalVisible: true }, () => {
        this._openModal();
      });
    } else if (!this.props.modalOpen && this.state.modalVisible) {
      this.setState({ modalVisible: false }, () => {
        this._closeModal();
      });
    }

    if (this.props.modalOpen != prevProps.modalOpen) {
      if (document.getElementById("modal-inner")) {
      
        let windowHeight = window.innerHeight;
        let modalHeight = document.getElementById("modal-inner").offsetHeight;

        this.setState({
          checkVerticalScroll: modalHeight + 100 > windowHeight,
        });
      }
    }
  }

  _openModal = () => {
    let top = window.pageYOffset || document.getElementById("page").scrollTop;
    this.setState(
      {
        modalVisible: true,
        topScrollConst: top,
      },
      () => {
        let element = document.getElementById("page");
        element.classList.toggle("page-lock");
        let elementWrapper = document.getElementById("wrapper");
        if (elementWrapper) elementWrapper.classList.toggle("page-blur");
        let elementWrapperBasket = document.getElementById("basket-list");
        if (elementWrapperBasket) elementWrapperBasket.classList.toggle("page-blur");
        ReactDOM.findDOMNode(element).style.top = -top + "px";
      }
    );
  };

  _closeModal = () => {
    let element = document.getElementById("page");
    element.classList.toggle("page-lock");
    let elementWrapper = document.getElementById("wrapper");
    if (elementWrapper) elementWrapper.classList.toggle("page-blur");
    let elementWrapperBasket = document.getElementById("basket-list");
    if (elementWrapperBasket) elementWrapperBasket.classList.toggle("page-blur");
    this.setState({ modalVisible: false }, () => {
      window.scrollTo(0, this.state.topScrollConst)
    });
  };

  render() {
    return (
      <div className="modal-wrap">

        <div
          className={
            "modal" +
            (this.props.overlayLight ? " modal-light" : "") +
            (this.props.modalOpen ? " show" : " hide") +
            (this.state.checkVerticalScroll ? " scroll" : " center")
          }
        >
          {this.props.modalOpen ? (
            <div
              className={"modal-inner" + " " + this.props.modal}
              id="modal-inner"
            >
              {this.props.modalInner}
            </div>
          ) : null}
        </div>

      </div>
    );
  }
}

export default UiModal;
