import React, { Component } from "react";
import InputMask from "react-input-mask";

import "./UiTextInput.scss";

class UiTextInputMasked extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>
        <InputMask
          className={
            "text-input" +
            (this.props.btnText ? " text-right" : "") +
            (this.props.warnText && this.props.warnText != ""
              ? " text-input-warn"
              : "")
          }
          mask="+7 (999) 999-9999"
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          onChange={(e) => this.props.callBack(e.target.value)}
        />
        {this.props.btnText ? (
          <button className="btn" onClick={this.props.onClick}>
            {this.props.btnText}
          </button>
        ) : null}
        {this.props.warnText && this.props.warnText != "" ? (
          <p className="text-input-warn">* {this.props.warnText}</p>
        ) : null}
      </div>
    );
  }
}
export default UiTextInputMasked;
