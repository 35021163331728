import React, { Component } from "react";

import "./UiHistoryCard.scss";

import { Icon } from "@iconify/react";
import deleteIcon from "@iconify/icons-zmdi/delete";

import deliveryIcon from "../../assets/images/demo/gift-1.png";

import UiBtnLight from "../buttons/UiBtnLight";
import UiSwitch from "../forms/UiSwitch";

class UiHistoryCard extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    let url = null;
 
    if (this.props.category == 0) url = `flowers/${this.props.url}`;
    if (this.props.category == 1) url = `bride/${this.props.url}`;
    if (this.props.category == 2) url = `flowers/${this.props.url}`;
    if (this.props.category == 3) url = `gifts/${this.props.url}`;
    if (this.props.category == 4) url = `classes/${this.props.url}`;
    if (this.props.category == 5) url = `subscribe/${this.props.url}`;
    if (this.props.category == 6) url = `events/${this.props.url}`;
    if (this.props.category == 7) url = `boxes/${this.props.url}`;
    if (this.props.category == 8) url = `special/${this.props.url}`;
 
    return (
      <div className="history-card">
        <div className="history-card-content">
          <a className="history-card-image" href={url}>
            {this.props.image ? (
              <img src={this.props.image} />
            ) : (
              <img className="delivery-image" src={deliveryIcon} />
            )}
          </a>
          <a className="history-card-info" href={url}>
            <h3>{this.props.title} {this.props.count ? " - " + this.props.count + " шт" : null}</h3>
            <p>{this.props.info}</p>
            {!this.props.paymentCard && this.props.image ? (
              <UiBtnLight btnText="В каталог" />
            ) : null}
          </a>
          <p className="history-card-price">{this.props.price}&nbsp;₽</p>
          <div className="history-card-delete">
            {this.props.type == "free" ? null : (
              <button
                className="btn btn-delete"
                onClick={() => this.props.onDelete()}
              >
                <Icon icon={deleteIcon} />
              </button>
            )}
          </div>
        </div>
        {this.props.special == "gift_box" ?
          <div className="switch-wrap">
            {this.props.type == "free" ? null : (
              <UiSwitch
                value={this.props.giftBox}
                callBack={(val) => { this.props.onChangeGiftBox(val) }}
                labelDisabled="Не заворачивать в подарочную упаковку"
                labelEnabled="Завернуть в подарочную упаковку"
              />
            )}
          </div>
          : null}
      </div>
    );
  }
}
export default UiHistoryCard;
