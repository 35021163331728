import React, { Component } from "react";
import { getOrders } from "../../services/Users";
import { loadUser } from "../../services/Auth";
import { Icon } from "@iconify/react";
import checkCircle from "@iconify/icons-zmdi/check-circle";

import "./Order.scss";

import UiBtnPinkOutline from "../../components/buttons/UiBtnPinkOutline";
import UiBtnPink from "../../components/buttons/UiBtnPink";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalLogin from "../../components/modals/UiModalLogin";

class OrderSuccessPage extends Component {
  state = {
    modalLoginActive: false,
  };

  componentDidMount() {
    window.ym(55588969, 'hit', '/success',{});
    window.gtag('set', 'page_path', '/success');

    let _user = loadUser();
    if (_user) {
      if ((_user) && (_user?.id) && (_user?.api_token)) {
        getOrders(_user.api_token, _user?.id).then((res) => {});
      }
    }
  }
 

  render() {
    return (
      <div ref={this.divPage} id="page">
        <div className="page-wrapper">
          {/* basket header */}
          <section className="section basket-header">
            <UiHeader
              callBack={(value) => { }}
              page="basket"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={true}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
           
          </section>

          {/* order content */}
          <section className="section order-content">
            <div className="wrapper">
              <div className="order-success">
                <Icon className="order-success-icon" icon={checkCircle} />
                <h3>Заказ успешно создан!</h3>
                <p>
                  Наш менеджер свяжется с вами при необходимости для уточнения
                  деталей
                </p>
                <div className="order-success-button">
                  <UiBtnPink
                    btnText="Перейти в профиль"
                    onClick={() => this.props.history.push("/profile")}
                  />
                </div>
                <div className="order-success-button">
                  <UiBtnPinkOutline
                    btnText="Вернуться в каталог"
                    onClick={() => this.props.history.push("/")}
                  />
                </div>
              </div>
            </div>
          </section>

          {/* basket footer */}
          <section className="section basket-footer">
            <UiFooter />
          </section>
        </div>

        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
          }}
        />
      </div>
    );
  }
}

export default OrderSuccessPage;
