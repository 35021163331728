import React, { Component } from "react";

import { ReactComponent as SvgLoaderWhite } from "../../assets/images/ui/loader-white.svg";

import "./UiBtnPink.scss";

class UiBtnPink extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"btn-pink-wrap" + (this.props.btnBig ? " btn-big" : "")}>
        {this.props.href ? (
          <a className="btn btn-pink" href={this.props.href}>
            <span>{this.props.btnText}</span>
            {this.props.btnNum && this.props.btnNum > 0 ? (
              <span>{this.props.btnNum}</span>
            ) : null}
          </a>
        ) : (
          <button
            className={
              "btn btn-pink" + (this.props.disabled ? " disabled" : "")
            }
            disabled={this.props.disabled}
            onClick={() => this.props.onClick()}
          >
            {this.props.loaderActive ? (
              <SvgLoaderWhite className="btn-loader-icon" />
            ) : (
              <span>
                <span>{this.props.btnText}</span>
                {this.props.btnNum && this.props.btnNum > 0 ? (
                  <span>
                    {this.props.btnNum}&nbsp;{this.props.priceBtn ? "₽" : null}
                  </span>
                ) : null}
              </span>
            )}
          </button>
        )}
      </div>
    );
  }
}
export default UiBtnPink;
