import {getEnvOfStorage} from "./EnvContext";


export const getSettings = (_id) => {

    return fetch(getEnvOfStorage()?.apiUrl + '/settings/all', {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
}

export const getInstagramPhoto = () => {

    return fetch('https://www.instagram.com/mateflowers/?__a=1', {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
}

export const getSpecialChapter = (_id) => {

    return fetch(`${getEnvOfStorage()?.apiUrl}/categories/id/${_id}`, {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
}


export const getAllCategories = (_id) => {

    return fetch(`${getEnvOfStorage()?.apiUrl}/categories/all`, {
        method: 'GET',
    }).then(function (response) {
        return response.json();
    });
}


export const getInterval = ( _date, _shop) => {

    return fetch(getEnvOfStorage()?.apiUrl + '/interval/get/available/all', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            date: _date,
            shop: _shop
        }),
    }).then((response) => {
        return response.json()
    });
}

export const getMinimalPrice = ( _date, _shop) => {
    return fetch(getEnvOfStorage()?.apiUrl + '/price/get/minimal/date', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            date: _date,
            shop: _shop
        }),
    }).then((response) => {
        return response.json()
    });
}

export const getDeliveryPrices = () => {
    return fetch(getEnvOfStorage()?.apiUrl + '/price/get/delivery', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        } 
    }).then((response) => {
        return response.json()
    });
}
