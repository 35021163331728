import React, { Component } from 'react';

import './UiGiftAddCard.scss';

import { Icon } from '@iconify/react';
import plusIcon from '@iconify/icons-zmdi/plus';

class UiGiftAddCard extends Component {

  state = {
    activeSlide: 0,
    giftChecked: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  _nextSlide() {
    if (this.props.imageList.length > this.state.activeSlide + 1) {
      this.setState({ activeSlide: this.state.activeSlide + 1 });
    } else {
      this.setState({ activeSlide: 0 })
    }
  }

  render() {

    var slideImageList = this.props.imageList.map((item, index) => {
      return (
        <div className={"gift-image-slide" + (index == this.state.activeSlide ? " active" : "")} key={index}>
          <img src={item.src} alt={item.alt} />
        </div>
      );
    });

    return (
      <div className={"gift-card gift-add-card" + (this.state.giftChecked ? " active" : "")} onClick={() => {
        this.props.click(!this.state.giftChecked);  
        this.setState({giftChecked: !this.state.giftChecked});
        }}>
        <div className="gift-card-image">
          <div className="gift-card-slider">
            {slideImageList}
          </div>
          <div className="add-gift-btn">
            <p>+ {this.props.price}&nbsp;₽</p>
          </div>
        </div>
        <div className="gift-card-info">
          <h3>{this.props.title}</h3>
        </div>
      </div>
    );
  }
}
export default UiGiftAddCard;