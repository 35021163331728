import React, { Component } from 'react';

import './UiSwitch.scss';

class UiSwitch extends Component {

  state = {
    checkActive: true,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    if(this.props.value != undefined){
      this.setState({ checkActive: this.props.value })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if( this.props.clear != undefined && this.props.clear){
      this.props.clearCallBack(false);
      this.setState({ checkActive: false });
    }
  }

  render() {
    return (
      <button className={"switch" + (this.state.checkActive ? " active" : "")} disabled={this.props.disabled} onClick={() => {
          this.setState({ checkActive: !this.state.checkActive },() => {
            this.props.callBack(this.state.checkActive ? 1 : 0);
          });
        }}>
        <div className="switch-check"></div>
        {this.state.checkActive ?
          <span className="switch-label">{this.props.labelEnabled}{this.props.labelEnabledBold ? <span> {this.props.labelEnabledBold}</span> : null}</span> :
          <span className="switch-label">{this.props.labelDisabled}{this.props.labelDisabledBold ? <span> {this.props.labelDisableddBold}</span> : null}</span>
        }
      </button>
    );
  }
}
export default UiSwitch;