import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./Contacts.scss";
// npm install --save-dev @iconify/react @iconify-icons/zmdi
import { Icon, InlineIcon } from "@iconify/react";
import whatsappIcon from "@iconify-icons/zmdi/whatsapp";
import mapIcon from "@iconify-icons/zmdi/map";
import emailIcon from "@iconify-icons/zmdi/email";
import headsetMic from "@iconify-icons/zmdi/headset-mic";

import aboutImage from "../../assets/images/about/about-image.jpg";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiPageTitle from "../../components/header/UiPageTitle";

import { Helmet } from "react-helmet";

import { storeData, retrieveData, getBasketNum } from "../../services/Storage";
import { getSettings } from "../../services/Settings";
import { GetFlowersByCategory } from "../../services/Flowers";
import UiBtnPink from "../../components/buttons/UiBtnPink";
import MainConstant from "../../constants/MainConstant";

class ContactsPage extends Component {
  state = {
    showTopBtn: false,
    scrollHeader: false,
    basketNum: 0,
    modalCatalogActive: false,
    modalLoginActive: false,
    user: null,

    settings: {},
    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },

    cart: [],
    mainList: [],
    giftsList: [],
    waifuList: [],
    manList: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });
  }

  _load() {
    let _cart = retrieveData("cart");
    let _user = retrieveData("mfUser");
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: getBasketNum(_cart) });
    }
    if (_user) {
      this.setState({ user: _user });
    }
    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });

    GetFlowersByCategory(0).then((res) => {
      this.setState({ mainList: res.response });
      console.log(res);
    });
    GetFlowersByCategory(1).then((res) => {
      this.setState({ waifuList: res.response });
    });
    GetFlowersByCategory(2).then((res) => {
      this.setState({ manList: res.response });
    });
    GetFlowersByCategory(3).then((res) => {
      this.setState({ giftsList: res.response });
    });
  }

  addToCart(_cart) {
    this.setState({ basketNum: getBasketNum(_cart) });
    storeData("cart", _cart);
  }

  render() {
    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>Контакты MATE flowers</title>
          <meta name="description" content="Как найти MATE flowers" />
          <meta name="keywords" content="MATE flowers контакты" />
        </Helmet>
        <div className="page-wrapper">
          {/* contacts header */}
          <section className="section about-header">
            <UiHeader
              callBack={(value) => { }}
              page="contacts"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>
          {/* contacts catalog */}
          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A2f73124a095def8e8b6882c294876f79d45d368cb13042dedcd5c13aa5827a4d&amp;source=constructor"
            width="100%"
            height="350"
            frameborder="0"
          ></iframe>
          <section className="section contacts">
            <div className="wrapper">
              <div className="contacts-wrap">
                <h3>РЕЖИМ РАБОТЫ: С 9:00 ДО 21:00</h3>
                <div className="contacts-info-wrap">
                  <div
                    className="contacts-info-item-link"
                  >
                    <div className="contacts-info-item">
                      <Icon
                        icon={mapIcon}
                        className="contacts-info-item-icon"
                      />
                      <h4>НАШИ АДРЕСА</h4>
                      <p>
                        <a href="#">Москва, ул. Серафимовича, д.2. c 9:00 до 21:00</a>
                        <br />
                       
                        <br />
                        <a href="#">Москва, пл. Журавлева, д.10, стр. 4, с 8.00 до 20.00</a>
                  
                      </p>
                    </div>
                  </div>
                  <a
                    className="contacts-info-item-link"
                    href="tel:+74951083299"
                  >
                    <div className="contacts-info-item">
                      <Icon
                        icon={headsetMic}
                        className="contacts-info-item-icon"
                      />
                      <h4>ТЕЛЕФОН</h4>
                      <p>+7 (495) 108-32-99</p>
                    </div>
                  </a> 
                  <a
                    className="contacts-info-item-link"
                    href={"https://api.whatsapp.com/send?phone="+MainConstant.WP_PHONE+"&text=%D0%94%D0%BE%D0%B1%D1%80%D0%BE%D0%B3%D0%BE%20%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8%20%D1%81%D1%83%D1%82%D0%BE%D0%BA!%20%D0%A5%D0%BE%D1%87%D1%83%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%BF%D0%BE%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D1%83%20%D0%B1%D1%83%D0%BA%D0%B5%D1%82%D0%B0."}
                  >
                    <div className="contacts-info-item">
                      <Icon
                        icon={whatsappIcon}
                        className="contacts-info-item-icon"
                      />
                      <h4>WHATSAPP</h4>
                      <p></p>
                    </div>
                  </a>
                  <a
                    className="contacts-info-item-link"
                    href="https://www.instagram.com/mateflowers/"
                  >
                    <div className="contacts-info-item">
                      <Icon
                        icon={emailIcon}
                        className="contacts-info-item-icon"
                      />
                      <h4>E-MAIL</h4>
                      <p>info@mateflowers.ru</p>
                    </div>
                  </a>
                </div>

                <div style={{
                  flexWrap: 'wrap',
                  justifyContent: "space-around",
                  width: "100%"
                }} >
                  <div style={{ marginBottom: 12 }}>
                    <UiBtnPink
                      btnText="Я ХОЧУ С ВАМИ СОТРУДНИЧАТЬ"
                      href="https://docs.google.com/forms/d/1PpIIY_7iPzb2NBzaWvzZC5beomzOGXIupIUnTzyAHi0/viewform?edit_requested=true"
                    />
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <UiBtnPink
                      btnText="ФОРМА СОИСКАТЕЛЯ"
                      href="https://docs.google.com/forms/d/e/1FAIpQLScT_sXjlrdHNtlWNtLIScLhNPaF-CaVY4wJSPyRwi5BoTeiPQ/viewform"
                    />
                  </div>
                </div>

              </div>
            </div>
          </section>



          <section className="section classes-footer">
            <div className="wrapper">
              <div className="contacts-wrap">
                <p>
                  ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ ГАЙДУКОВА КРИСТИНА ДМИТРИЄВНА<br />
                  <br />
                  Юридический адрес: 155710, РОССИЯ. ИВАНОВСКАЯ ОБЛ, САВИНСКИЙ Р-Н, П САВИНО, УЛ ИМ.ХЛЕБНИКОВА, Д7
                  <br />
                  ИНН: 371122369833<br />
                  ОГРН: 319370200033300<br />
                 
                  Р/С: 40802810300001236869<br />
                  Банк: АО “Тинькофф Банк”<br />
                  БИК Банка: 044525974<br />
                </p>

              </div>
            </div>
          </section>


          {/* contacts footer */}
          <section className="section classes-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiBtnBasket
          cartNum={this.state.basketNum}
          infoActive={this.state.infoActive}
          callBack={() => this.setState({ infoActive: false })}
        />

        {/* modals */}
        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.props.history.push("/profile");
          }}
        />
      </div>
    );
  }
}

export default ContactsPage;
