import React, { Component } from "react";

import "./Profile.scss";

import { Icon } from "@iconify/react";
import cardGiftcard from "@iconify/icons-zmdi/card-giftcard";

import appStoreLogo from "../../assets/images/footer/app-store-badge-ru.svg";
import googlePlayLogo from "../../assets/images/footer/google-play-badge-ru.svg";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnLight from "../../components/buttons/UiBtnLight";
import UiBtnPink from "../../components/buttons/UiBtnPink";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiOrderCard from "../../components/cards/UiOrderCard";
import UiTextInput from "../../components/forms/UiTextInput";

import { Helmet } from "react-helmet";

import { loadUser, saveUser, logout } from "../../services/Auth";
import { getDayMonthYearName } from "../../components/common/Dates";
import { retrieveData, getBasketNum } from "../../services/Storage";
import { getOrders, updateUser, getUser } from "../../services/Users";
import {getBalance, updateClient} from "../../services/Loyalty";
import {setTotalBonuses} from "../../Store/Slices/LoyaltySlice";
import {connect} from "react-redux";

class ProfilePage extends Component {
  state = {
    showTopBtn: false,
    scrollHeader: false,
    basketNum: 0,
    activeProfile: true,
    phoneDisabled: false,

    address: "",
    name: "",
    email: "",

    user: {
      phone: "",
      bonus: 0,
    },
    ordersList: [],
    crmOrdersList: [],
    modalLoginActive: false,
    modalOrderActive: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });
  }

  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = -82;
    if (window.innerWidth < 991) headerOffset = -82;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  _updateProfile() {
    let _data = {
      name: this.state.name,
      address: this.state.address,
      email: this.state.email,
    };
    updateUser(
      this.state.user.api_token,
      this.state.user.id,
      this.state.email,
      this.state.address,
      this.state.name
    ).then((res) => {
      console.log(res);
      this.setState({ loader: false });
      if (res?.success) {
        if (res?.response?.id) {
          updateClient(res?.response);
          saveUser(res.response);
        }
      }
    });
  }

  _logout() {
    logout();
    this.props.history.push("/");
  }

  _copy(text) {
    var input = document.createElement("textarea");
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
  }

  _getCrmInfo(_id){
    let s = 5;
    this.state.crmOrdersList.map((item) => {
      if( parseInt(item.externalId.replace("SMF","")) == _id) s = item.status;
    })
    if(s == 'confirmed') s = 1;
    if(s == 'assembling') s = 2;
    if(s == 'send-to-delivery') s = 4;
    if(s == 'test') s = 6;
    if(s == 'cancel') s = 6;
    if(s == 'assembling-complete') s = 3;
    if(s == 'confirmed') s = 1;
    if(s == 'complete') s = 5;

    return s;
  }

  _load() {
    let _user = loadUser();
    this.setState({ user: _user });
    let _cart = retrieveData("cart");

    if (_cart) {

      this.setState({ basketNum: getBasketNum(_cart) });
    }
    if (_user == null) this.props.history.push("/");
    if (_user) {
      this.setState({
        email: _user.email,
        name: _user.name,
        address: _user.address,
        user: _user,
      });

      getUser(_user.api_token).then((res) => {
        console.log(res);
        if (res.success) {
          if (res.response) {
            saveUser(res.response);
            this.setState({ user: res.response });
          }

        }
      });

      if ((_user) && (_user?.id) && (_user?.api_token)) {
        getOrders(_user.api_token, _user?.id).then((res) => {
          if (res?.success) {
            this.setState({ 
              ordersList: res?.response?.reverse(),
              crmOrdersList: res?.crm?.orders 
            });
          } else {
            if (res?.message == "Too Many Attempts.") {

            } else {
              logout();
              this.props.history.push("/");
            }
          }
        });
      }

    }
    /**Получить количество бонусов для пользователя*/
    this.recalculateBonuses(_cart, _user);
  }

  _getStatus(_item) {
    var val = 0;
    if (_item.is_pay == 0) val = 0;
    if (_item.is_pay == 1) val = 1;
    if (_item.is_close == 1) val = 2;
    return val;
  }

  _getComposition(_val) {
    var parsed = [],
      arr = [];
    var str = "";
    try {
      parsed = JSON.parse(_val);
    } catch (e) { }

    parsed.map((item) => arr.push(item.title));
    return "(" + arr.join(",") + ")";
  }

  /**Получить количество бонусов для пользователя*/
  recalculateBonuses(cart, user) {
    getBalance(user).then((data) => {
      if (data?.client) {
        this.props.setTotalBonuses(data?.client?.bonuses);
      }
    });
  }

  getOrderId(item) {
      return  item?.id
  }

  render() {
    let orders = this.state.ordersList.map((item, index) => {
      return (
        <UiOrderCard
          key={index}
          onClick={() => this.setState({ modalOrderActive: true })}
          status={this._getCrmInfo(item.id)}
          date={"#" + this.getOrderId(item) + " - " + getDayMonthYearName(item.created_at)}
          info={item.price + " ₽ " + this._getComposition(item.compositions)}
        />
      );
    });

    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>Профиль MATE flowers</title>
          <meta name="description" content="Профиль MATE flowers" />
          <meta name="keywords" content="Доставка букетов Москва, купить цветы Москва" />
        </Helmet>
        <div className="page-wrapper">
          {/* profile header */}
          <section className="section profile-header">
            <UiHeader
              callBack={(value) => { }}
              page="profile"
              isLogin={this.state.user}
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              loginClick={() => this.setState({ modalLoginActive: true })}
            />
          </section>

          {/* profile content */}
          <section className="section profile-content">
            <div className="wrapper">
              <div className="profile-content-inner">
                <div className="profile-mobile-tabs">
                  <button
                    className={this.state.activeProfile ? "active" : ""}
                    onClick={() => this.setState({ activeProfile: true })}
                  >
                    Профиль
                  </button>
                  <button
                    className={!this.state.activeProfile ? "active" : ""}
                    onClick={() => this.setState({ activeProfile: false })}
                  >
                    История заказов
                  </button>
                </div>
                <div
                  className={
                    "profile-orders" +
                    (!this.state.activeProfile ? " active" : "")
                  }
                >
                  <h2>Мои заказы</h2>
                  <p>История ваших заказов MATE flowers</p>
                  <div className="profile-orders-list">{orders}</div>
                </div>
                <div
                  className={
                    "profile-user" + (this.state.activeProfile ? " active" : "")
                  }
                >
                  <h2>Профиль MATE flowers</h2>
                  <div className="profile-form">
                    <UiTextInput
                      placeholder="Ваше имя"
                      value={this.state.name}
                      callBack={(res) => this.setState({ name: res })}
                    />
                    <UiTextInput
                      placeholder={this.state.user.phone}
                      disabled={this.state.phoneDisabled}
                      btnText="Изменить"
                      onClick={() => {}}
                    />
                    <UiTextInput
                      placeholder="Адрес доставки"
                      value={this.state.address}
                      callBack={(res) => this.setState({ address: res })}
                    />
                    <UiTextInput
                      placeholder="E-mail *"
                      value={this.state.email}
                      callBack={(res) => this.setState({ email: res })}
                    />
                    <p>* Для отправки электронных чеков при оплате</p>
                    <UiBtnPink
                      btnText="Сохранить изменения"
                      onClick={() => this._updateProfile()}
                    />
                    <UiBtnLight
                      btnText="Выход"
                      onClick={() => this._logout()}
                    />
                  </div>
                  <h2>Мои бонусы</h2>
                  <div className="profile-bonuses">
                    <div className="profile-bonuses-count">
                      <p>Бонусов на вашем счету</p>
                      <p>
                        <span>{this.props.totalBonuses}</span>{" "}
                        <Icon className="bonus-icon" icon={cardGiftcard} />
                      </p>
                    </div>
                    <p>
                      С подробностями и условиями участия в бонусной программе Mate Club можно ознакомиться по <a href="/loyalty">ссылке</a>
                    </p>
                    {/* 
                    <p>
                      Получайте бонусы от суммы покупки.
                      <br />
                      Делитесь промокодом 300 ₽ на первую покупку.
                      <br />+ 300 бонусов за каждого оформившего заказ друга.
                    </p>
                    <UiTextInput
                      value={"matecode" + this.state.user.id}
                      btnText="Копировать"
                      onClick={() =>
                        this._copy("matecode" + this.state.user.id)
                      }
                    />
                    */}
                  </div>
                  <div className="profile-app">
                    <h3>Скачайте приложение</h3>
                    <p>Получайте больше бонусов при заказе из приложения</p>
                    <div className="profile-app-links">
                      <a href="#">
                        <img src={appStoreLogo} />
                      </a>
                      <a href="#">
                        <img src={googlePlayLogo} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* profile footer */}
          <section className="section profile-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiBtnBasket cartNum={this.state.basketNum} />

        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTotalBonuses: (value) => dispatch(setTotalBonuses(value)),
  }
};

function mapStateToProps(state, ownProps) {
  const { loyalty } = state

  return { totalBonuses: loyalty.totalBonuses }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
