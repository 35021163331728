import React, { Component } from "react";

import "./UiModal.scss";
import "./UiModalLogin.scss";

import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-zmdi/close";

import UiBtnLight from "../buttons/UiBtnLight";
import UiBtnPink from "../buttons/UiBtnPink";
import UiModal from "./UiModal";
import UiTextInput from "../forms/UiTextInput";
import UiTextInputMasked from "../forms/UiTextInputMasked";

import { saveUser } from "../../services/Auth";
import { registrationUser, loginUser } from "../../services/Users";

class UiModalAlert extends Component {
    state = {
        modalActive: false,
    };

    constructor(props) {
        super(props);
    }



    render() {
        const modalInner = (
            <div className="modal-login">
                <div className="modal-body">
                    <button
                        className="modal-close-btn"
                        onClick={() => {
                            this.props.modalClose();
                        }}
                    >
                        <Icon className="modal-close-btn-icon" icon={closeIcon} />
                    </button>
                    <div className="login-form">
                        <h3>{this.props.text}</h3>
                       

                        <div className="login-send-pass">
                            <UiBtnPink
                                loaderActive={this.state.loader}
                                
                                btnText={"Закрыть"}
                                onClick={() =>  this.props.modalClose()}
                            />
                        </div>

                    </div>
                </div>
            </div>
        );

        return (
            <UiModal
                modal="login"
                modalInner={modalInner}
                modalOpen={this.props.modalOpen}
            />
        );
    }
}
export default UiModalAlert;
