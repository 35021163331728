import React, { Component } from "react";

import "./UiBasketCard.scss";

import { Icon } from "@iconify/react";
import deleteIcon from "@iconify/icons-zmdi/delete";
import plusIcon from "@iconify/icons-zmdi/plus";
import minusIcon from "@iconify/icons-zmdi/minus";

import UiSwitch from "../forms/UiSwitch";

class UiBasketCard extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <div
        className={
          "basket-card" + (this.props.type == "free" ? " no-line" : "")
        }
      >
        <div className="basket-card-image">
          <div className="basket-card-image-wrap" >
            {this.props.type == "free" ? (
              <img src={require("../../assets/images/demo/gift-1.png")} />
            ) : (
              <img src={this.props.image} style={{ cursor: "pointer" }} onClick={() => this.props.onShow() }/>
            )}
          </div>
        </div>
        <div className="basket-card-info"  style={this.props.type != "free" ? { cursor: "pointer" } : null} onClick={() => { if(this.props.type != "free" ) this.props.onShow(); }}>
          <h3>{this.props.title}</h3>
          {this.props.textInfo ? <p>{this.props.textInfo}</p> : null}
          {/*this.props.type == "free" ? null : this.props.category == 3 ? (
            <UiSwitch
              value={this.props.giftBox}
              callBack={(val) => {
                this.props.onChangeGiftBox(val);
              }}
              labelDisabled="Не заворачивать в подарочную упаковку"
              labelEnabled="Завернуть в подарочную упаковку"
            />
            ) : null*/}
        </div>
        <div className="basket-card-price-wrap">
          {this.props.type == "free" ? (
            <div className="basket-free-btn">
              <p>1&nbsp;шт.</p>
            </div>
          ) : (
            <div className="basket-card-info-btns">
              <button className="btn" onClick={() => this.props.onMinus()}>
                <Icon icon={minusIcon} />
              </button>
              <input value={this.props.count} />
              <button className="btn" onClick={() => this.props.onPlus()}>
                <Icon icon={plusIcon} />
              </button>
            </div>
          )}
          <div className="basket-card-price">
            <p>{this.props.price}&nbsp;₽</p>
          </div>
        </div>
        <div className="basket-card-delete">
          {this.props.onDelete ? (
            <button
              className="btn btn-delete"
              onClick={() => this.props.onDelete()}
            >
              <Icon icon={deleteIcon} />
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}
export default UiBasketCard;
