import React, { Component } from "react";

import "./UiModal.scss";
import "./UiModalRules.scss";

import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-zmdi/close";

import UiModal from "./UiModal";
import UiBtnPink from "../buttons/UiBtnPink";

class UiModalRules extends Component {
  state = {
    paymentType: 0,
    deliveryType: 0,
    selectDeliveryTime: "09:00 - 12:00",
  };

  constructor(props) {
    super(props);
  }

  render() {
    const modalInner = (
      <div className="modal-rules">
        <div className="modal-body">
          <button
            className="modal-close-btn"
            onClick={() => this.props.modalClose()}
          >
            <Icon className="modal-close-btn-icon" icon={closeIcon} />
          </button>
          <div className="modal-rules-wrap">
            <h2>Правила работы сервиса MATE flowers</h2>
            <h3>Курьер опоздал более чем на 20 минут, доставка за наш счет!</h3>
            <h3>
              А можно вернуть деньги и букет? Не можно, а нужно если вам хоть
              что-то не понравилось!)
            </h3>
            <p>
              Мы уважаем мнение наших клиентов и доверяем ему. Если букет,
              который вы приобрели в нашей мастерской не соответствует вашим
              представлениям о качестве и стиле - верните его обратно. Заранее
              обсудив возврат с менеджером.
            </p>
            <h3>Соответствие букета!</h3>
            <p>
              Мы сделали удобный сайт с каталогом букетов, для того чтобы был
              понятен стиль, размер и цветовое настроение букетов. К сожалению,
              мы не можем гарантировать 100% копию букета, ежедневно в нашу
              мастерскую приезжают цветочки с разных уголков света, и они имеют
              свойство отличаться. Со своей стороны, мы гарантируем соблюсти
              основной состав и стиль вашего букета, в этом вы можете не
              сомневаться.
            </p>
            <div className="modal-rules-control">
              <UiBtnPink btnText="ОК" onClick={() => this.props.modalClose()} />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <UiModal
        modal="rules"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}
export default UiModalRules;
