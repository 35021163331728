import React, { Component } from "react";
import ReactDOM from "react-dom";
import Slider, { Range } from "rc-slider";
import { FixedSizeList as List } from 'react-window';

import "./UiCatalog.scss";
import "rc-slider/assets/index.css";

import { retrieveData } from "../../services/Storage";
import Env from "../../services/Env";

class UiCatalogFilter extends Component {
  state = {
    pageTitle: "Спец. предложения",
    menuList: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    let title = retrieveData("special");
    if(title) {
      this.setState({ pageTitle: title })
    }
    let _menuList = retrieveData("menuList");
    if(_menuList){
      this.setState({ menuList: _menuList })
    }
    
  }

  componentDidUpdate(prevProps , prevState){
    if(prevState.pageTitle !== retrieveData("special")) this.setState({ pageTitle: retrieveData("special") });



  }

  render() {
    let menuLinks = this.state.menuList.map((item, index) => {
      return (
        
          <a
             key={index}
            className={this.props.page == item.title_url? "active" : ""}
            href={`/special/${item.title_url}`}
          > 
          {item.title}</a>
      
      );
    });

    return (
      <div className="mobile-catalog-filter">
        <div className="mobile-filter-type">
          <a
            className={this.props.page == "bouquet" ? "active" : ""}
            href="/"
          >
            Букеты
          </a>
          <a
            className={this.props.page == "gifts" ? "active" : ""}
            href="/gifts"
          >
            Подарки
          </a>
          {menuLinks}
        </div>
      </div>
    );
  }
}
export default UiCatalogFilter;
