import React, { Component } from 'react';

import { Icon, InlineIcon } from '@iconify/react';
import chevronRight from '@iconify/icons-zmdi/chevron-right';

import './UiPageTitle.scss';

class UiPageTitle extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    if (this.props.list) {
      let path = this.props.list.map((item, index) => {
        return (
          <div key={index} onClick={() => {
            this.props.onClick(item.value)
          }}>
            <Icon className="title-icon" icon={chevronRight} />
            <p>{item.label}</p>
          </div>
        )
      })
      return (
        <div className="page-title-bg" /*style={{ backgroundImage: "url(" + this.props.backImg + ")" }}*/>
          <div className="title-shadow">
            <div className="wrapper">
              <div className="page-title-wrap">
                <div className="breadcrumbs">
                  <a href="/">MATE flowers</a>
                  <Icon className="title-icon" icon={chevronRight} />
                  <a href={this.props.pageLink}>{this.props.pageName}</a>
                  {path}
                </div>
                <h2 className="page-title">{this.props.title}</h2>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="page-title-bg" /*style={{ backgroundImage: "url(" + this.props.backImg + ")" }}*/>
          <div className="title-shadow">
            <div className="wrapper">
              <div className="page-title-wrap">
                <div className="breadcrumbs">
                  <a href="/">MATE flowers</a>
                  <Icon className="title-icon" icon={chevronRight} />
                  <a href={this.props.pageLink}>{this.props.pageName}</a>
                </div>
                <h2 className="page-title">{this.props.title}</h2>
              </div>
            </div>
          </div>
        </div>
      );
    }

  }
}
export default UiPageTitle;