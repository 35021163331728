const PROFILE_ROUTE = "/profile";
const LOYALTY_ROUTE = "/loyalty";
const LOGIN_ROUTE = "/login";

const LOYALTY_PAGE = "loyalty";

export {
    PROFILE_ROUTE,
    LOGIN_ROUTE,
    LOYALTY_ROUTE,
    LOYALTY_PAGE,
}