import React, { Component } from "react";

import "./UiModal.scss";
import "./UiModalGift.scss";

import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-zmdi/close";
import arrowLeft from "@iconify/icons-zmdi/arrow-left";
import arrowRight from "@iconify/icons-zmdi/arrow-right";
import cardGiftcard from "@iconify/icons-zmdi/card-giftcard";

import UiBtnPink from "../buttons/UiBtnPink";
import UiCheckList from "../forms/UiCheckList";
import UiModal from "./UiModal";

class UiModalGift extends Component {
  state = {
    activeSlide: 0,
    additionalCart: [],
    additionalPrice: 0,
    giftTypesList: [
      { value: 0, label: "Вариант подарка №1" },
      { value: 1, label: "Вариант подарка №2" },
      { value: 2, label: "Вариант подарка №3" },
    ],
  };

  constructor(props) {
    super(props);
  }

  _nextSlide() {
    if (this.props.imageList.length > this.state.activeSlide + 1) {
      this.setState({ activeSlide: this.state.activeSlide + 1 });
    } else {
      this.setState({ activeSlide: 0 });
    }
  }

  _prevSlide() {
    if (this.state.activeSlide > 0) {
      this.setState({ activeSlide: this.state.activeSlide - 1 });
    } else {
      this.setState({ activeSlide: this.props.imageList.length - 1 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.modalOpen == false && this.props.modalOpen) {
      this.setState({
        isClear: true,
        additionalPrice: 0,
        additionalCart: [],
      });
    }
  }

  calcTotalPrice() {
    return (
      parseFloat(this.props.price) + parseFloat(this.state.additionalPrice)
    );
  }

  addAdditionalToCart(val, item) {
    item.count = 1;

    if (val) {
      let arr = this.state.additionalCart;
      arr.push(item);
      this.setState({ additionalCart: arr }, () => {
        let p = 0;
        this.state.additionalCart.map((_item, _index) => (p = p + _item.price));
        this.setState({ additionalPrice: p });
      });
    } else {
      let kk = 0;
      this.state.additionalCart.map((_item, _index) =>
        item.id == item.id ? (kk = _index) : null
      );
      let arr = this.state.additionalCart;
      arr.splice(kk, 1);
      this.setState({ additionalCart: arr }, () => {
        let p = 0;
        this.state.additionalCart.map((_item, _index) => (p = p + _item.price));
        this.setState({ additionalPrice: p });
      });
    }
  }

  hasInCart(id) {
    let fl = false;
    this.props.cart.map((item) => {
      if (id == item.id) fl = true;
    });
    return fl;
  }

  toCart() {
    let arr = [];
    let _item = this.props.item;
    _item.count = 1;
    if (this.state.additionalCart.length > 0) {
      arr = arr.concat(this.state.additionalCart);
    }
    if (this.hasInCart(_item.id)) {
      _item.count = _item.count + 1;
    } else {
      arr = arr.concat(_item);
    }
    return arr;
  }

  render() {
    var slideImageList = this.props.imageList.map((item, index) => {
      return (
        <div
          className={
            "modal-catalog-image-slide" +
            (index == this.state.activeSlide ? " active" : "")
          }
          key={index}
        >
          <img src={item.src} alt={item.alt} />
        </div>
      );
    });

    var controlsList = this.props.imageList.map((item, index) => {
      return (
        <button
          className={
            "slide-btn" + (index == this.state.activeSlide ? " active" : "")
          }
          key={index}
        ></button>
      );
    });

    const modalInner = (
      <div className="modal-gift">
        <div className="modal-body">
          <button
            className="modal-close-btn"
            onClick={() => this.props.modalClose()}
          >
            <Icon className="modal-close-btn-icon" icon={closeIcon} />
          </button>
          <div className="modal-catalog-top">
            <div className="modal-catalog-image">
              <div className="modal-catalog-slider">
                {slideImageList}
                <div className="modal-catalog-controls">{controlsList}</div>
              </div>
              {this.props.imageList.length == 1 ? null : (
                <div className="modal-catalog-control">
                  <button onClick={() => this._prevSlide()}>
                    <Icon icon={arrowLeft} />
                  </button>
                  <button onClick={() => this._nextSlide()}>
                    <Icon icon={arrowRight} />
                  </button>
                </div>
              )}
            </div>
            <div className="modal-catalog-info">
              <div className="modal-catalog-info-settings">
                {this.props.filter != 0 ? (
                  <div className="filter-label">
                    <span>
                      {this.props.filter == 1
                        ? "Новинка"
                        : this.props.filter == 2
                          ? "Limited"
                          : this.props.filter == 3
                            ? "Лидер продаж"
                            : null}
                    </span>
                  </div>
                ) : null}
                <h3>{this.props.title}</h3>
                <p>{this.props.description}</p>
                <UiCheckList checkList={this.state.giftTypesList} />
                <p>Описание: {this.props.composition}</p>
              </div>
              <div className="modal-catalog-info-price">
                <p>
                  <Icon icon={cardGiftcard} />{" "}
                  <span>
                    Вы получите {this.props.price * (1 / 500)} бонусов
                  </span>
                </p>
                <UiBtnPink
                  btnText={
                    "Добавить в корзину за " + this.calcTotalPrice() + " ₽"
                  }
                  onClick={() => this.props.onClick(this.toCart())}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <UiModal
        modal="gift"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}
export default UiModalGift;
