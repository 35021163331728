import React, { Component } from "react";

import "./Classes.scss";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiCatalogFilter from "../../components/catalog/UiCatalogFilter";
import UiCatalogCard from "../../components/cards/UiCatalogCard";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalCatalog from "../../components/modals/UiModalCatalog";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiPageTitle from "../../components/header/UiPageTitle";

import { Helmet } from "react-helmet";

import { storeData, retrieveData, getBasketNum } from "../../services/Storage";
import { getSettings, getAllCategories } from "../../services/Settings";
import { GetFlowersByCategory } from "../../services/Flowers";

class SpecialPage extends Component {
  state = {
    showTopBtn: false,
    scrollHeader: false,
    basketNum: 0,
    modalCatalogActive: false,
    modalLoginActive: false,
    user: null,
    specialChapterTitle: "",
    loader: true,

    settings: {},
    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },

    cart: [],
    classesList: [],
    giftsList: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });
  }

  _load() {

    console.log("his.props.match.params.", this.props.match.params);

    let _cart = retrieveData("cart");
    let _user = retrieveData("mfUser");
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: getBasketNum(_cart) });
    }
    if (_user) {
      this.setState({ user: _user });
    }
    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });

    getAllCategories().then((res) => {
      let id = null;
      res.response.map((item) => {
        if (item.title_url == this.props.match.params.name) {
          GetFlowersByCategory(item.id).then((resFlowers) => {
            this.setState(
              {
                classesList: resFlowers.response,
                loader: false,
              },
              () =>
                this._openFlower(
                  resFlowers.response,
                  this.props.match.params.id
                )
            );
          });

          this.setState({
            specialChapterTitle: item.title,
          });
        }
      });
    });
  }

  hasInCart(id) {
    let fl = false;
    this.state.cart.map((item) => {
      if (id == item.id) fl = true;
    });
    return fl;
  }

  _openFlower(arr, id = null) {
    if (id) {
      arr.map((item) => {
        if (item.id == id || item.title_url == id) {
          this.setState({
            modalCatalogActive: true,
            selectedItem: item,
          });
        }
      });
    }
  }

  _toCart(item) {
    let arr = this.state.cart;
    let _item = item;
    if (_item.category == 1) _item.price = 0;
    _item.count = 1;
    _item.color = 0;
    _item.giftbox = false;
    if (this.hasInCart(_item.id)) {
      let index = 0;
      arr.map((arrItem, arrIndex) => {
        if (arrItem.id == item.id) index = arrIndex;
      });
      arr[index].count = arr[index].count + 1;
    } else {
      arr = arr.concat(_item);
    }
    return arr;
  }

  addToCart(_cart) {
    this.setState({ basketNum: getBasketNum(_cart), cart: _cart });
    storeData("cart", _cart);
  }

  render() {
    var list = this.state.classesList.map((item, index) => {
      return (
        <UiCatalogCard
          key={index}
          imageList={item.imgs}
          title={item.title}
          price={"₽ " + item.price}
          shopClick={() => {
            this.addToCart(this._toCart(item));
            this.setState({ modalCatalogActive: false });
          }}
          openClick={() => {
            this.props.history.push(`/special/${this.props.match.params.name}/${item.title_url}`);
            this._openFlower(this.state.classesList, item.title_url);
          }}
          category="0"
          filter={item.type}
        />
      );
    });

    return (
      <div ref={this.divPage} id="page">
        {!this.state.loader ? (
          <Helmet>
            <title>{this.state.specialChapterTitle} от MATE flowers</title>
            <meta
              name="description"
              content="Стильный и современный декор для вашего мероприятия"
            />
            <meta
              name="keywords"
              content="оформление мероприятий москва, цветочный декор"
            />
          </Helmet>
        ) : null}
        <div id="wrapper" className="page-wrapper">
          {/* gifts header */}
          <section className="section classes-header">
            <UiHeader
              callBack={(value) => {}}
              page="special"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>

          <UiCatalogFilter page={this.props.match.params.name} />
          {/* page title */}
          <section className="section classes-title">
            <UiPageTitle
              backImg={require("../../assets/images/header/bg-breacrum.jpg")}
              pageLink="/classes"
              pageName={this.state.specialChapterTitle}
              title={this.state.specialChapterTitle}
            />
          </section>
          {/* gifts catalog */}
          <section className="section classes-catalog">
            <div className="wrapper">
              <div className="classes-catalog-wrap">
                <div className="classes-cards">{list}</div>
              </div>
            </div>
          </section>
          {/* gifts footer */}
          <section className="section classes-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />
        <UiLoader modalOpen={this.state.loader} />

        <UiBtnBasket
          cartNum={this.state.basketNum}
          infoActive={this.state.infoActive}
          callBack={() => this.setState({ infoActive: false })}
        />

        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.props.history.push("/profile");
          }}
        />

        <UiModalCatalog
          modalOpen={this.state.modalCatalogActive}
          modalClose={() => {
            this.props.history.push(`/special/${this.props.match.params.name}`);
            this.setState({ modalCatalogActive: false });
          }}
          imageList={this.state.selectedItem.imgs}
          item={this.state.selectedItem}
          title={this.state.selectedItem.title}
          filter="1" 
          description={this.state.selectedItem.description}
          composition={this.state.selectedItem.composition}
          giftsList={this.state.giftsList}
          colors={
            this.state.selectedItem.modifications != null
              ? this.state.selectedItem.modifications
                  .split(",")
                  .filter((elem, index, self) => {
                    return index === self.indexOf(elem);
                  })
              : []
          }
          price={this.state.selectedItem.price}
          cart={this.state.cart}
          settings={this.state.settings}
          onClick={(cart) => {
            this.addToCart(this._toCart(cart));

            this.setState({ modalCatalogActive: false });
          }}
        />
      </div>
    );
  }
}

export default SpecialPage;
