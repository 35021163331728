import React, { useEffect, useState } from "react";
import UiHeader from "../../components/header/UiHeader";
import './index.scss'
import { loadUser } from "../../services/Auth";
import { getBasketNum, retrieveData } from "../../services/Storage";
import { CART } from "../../services/Loyalty";
import UiPageTitle from "../../components/header/UiPageTitle";
import { Helmet } from "react-helmet";
import { LOGIN_ROUTE, LOYALTY_PAGE, LOYALTY_ROUTE, PROFILE_ROUTE } from "../Constants/PageRoutes";
import UiBtnPink from "../../components/buttons/UiBtnPink";


const LoyaltyPage = (props) => {
    //const navigate = use
    const [settings, setSettings] = useState({
        showTopBtn: false,
        scrollHeader: false,
        mobileBasket: false,
        modalLoginActive: false,
        pageTitle: "Клуб друзей Mate Club",
        pageName: "Система лояльности",
        basketNum: 0,
        user: null,
        cart: [],
    });

    const loadPage = () => {
        let cart = retrieveData(CART);
        let user = loadUser();
        setSettings(prevState => ({ ...prevState, user: user }));
        if (cart) {
            setSettings(prevState => ({ ...prevState, cart: cart }));
            setSettings(prevState => ({ ...prevState, basketNum: getBasketNum(cart) }));
        }
    }

    useEffect(() => {
        loadPage();
        console.log("useEffect")
    }, []);

    return (
        <div id="page">
            <Helmet>
                <title>{settings.pageTitle}</title>
                <meta name="description" content="К каждому букету вы можете приобрести подарок из нашей коллекции" />
                <meta name="keywords" content="Доставка подарков москва, купить цветы и подарки москва" />
            </Helmet>
            <div id="wrapper" className="page-wrapper">
                <section className="section loyalty-header">
                    <UiHeader
                        callBack={(value) => { }}
                        page={LOYALTY_PAGE}
                        scrollHeader={settings.scrollHeader}
                        basketNum={settings.basketNum}
                        isLogin={settings.user}
                        loginClick={() => { props.history.push(LOGIN_ROUTE) }}
                        profileClick={() => { props.history.push(PROFILE_ROUTE) }}
                    />
                </section>
                {/* page title ../../assets/images/header/bg-breacrum.jpg*/}
                <section className="section">
                    <UiPageTitle
                        backImg={require("../../assets/images/header/bg-breacrum.jpg")}
                        pageLink={LOYALTY_ROUTE}
                        pageName={settings.pageName}
                        title={settings.pageTitle}
                    />
                </section>
                <section className="section loyalty-content">
                    <div className="wrapper">
                        <div className="page-content">
                            <div className="p-content">
                                <span className="subTitle1">Стань участником Mate Club</span><br />

                                <p>Пришло время ещё раз выразить благодарность за вашу любовь.
                                    Вступайте в нашу программу лояльности, чтобы наша дружба стала ещё крепче, а ваши покупки более приятными.<br />
                                    Чтобы стать участником клуба, используйте ссылку ниже. После установки электронной карты клиента, вы получите <b>250 бонусов в подарок.</b>
                                </p>
                                <div className="bonus-card">
                                    <UiBtnPink
                                        btnText="ПОЛУЧИТЬ БОНУСНУЮ КАРТУ"
                                        href="https://form.maxma.com/mateflowers?utm_source=web"
                                    />
                                </div>
                                <p>Уровни карт лояльности</p>

                                <table className="bonus-table">
                                    <thead>
                                        <tr>
                                            <th>Название уровня</th>
                                            <th>Процент начисления</th>
                                            <th>Сумма покупок</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Бронза</td>
                                            <td>2,5%</td>
                                            <td>Регистрация</td>
                                        </tr>
                                        <tr>
                                            <td>Серебро</td>
                                            <td>5%</td>
                                            <td>От 10 000₽</td>
                                        </tr>
                                        <tr>
                                            <td>Золото</td>
                                            <td>7,5%</td>
                                            <td>От 20 000₽</td>
                                        </tr>
                                        <tr>
                                            <td>Платина</td>
                                            <td>10%</td>
                                            <td>От 30 000₽</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p>Что входит в бонусную программу?</p>
                                <ul>
                                    <li>Оплачивайте до 15% покупок баллами в интернете или на розничных кассах</li>
                                    <li>Получайте до 5% кешбэк за заказ</li>
                                    <li>Уникальные промокоды и акции</li>
                                    <li>Apple Wallet и Google Wallet карта</li>
                                </ul>
                                <br />
                                <p>Актуальный остаток бонусов вы всегда можете проверить в личном кабинете сайта и приложения, а также установив бонусную карту в кошелек на свой смартфон.</p>
                            </div>
                            <div className="img-content">
                                <img src={require("../../assets/images/loyalty/loyalty_prog.png")} />
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </div>
    );
}

export default LoyaltyPage;