import React, { Component } from "react";

import "./UiBtnBasket.scss";

import { Icon } from "@iconify/react";
import shoppingCart from "@iconify/icons-zmdi/shopping-cart";

class UiBtnBasket extends Component {
  state = {
    infoActive: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.infoActive && prevProps.infoActive == false) {
      this.setState({ infoActive: true });
      setTimeout(() => {
        this.setState({ infoActive: false });
        this.props.callBack(true);
      }, 3500);
    }
  }

  render() {
    return (
      <a className="mobile-basket-wrap" href="/basket">
        <div className="mobile-basket">
          <Icon icon={shoppingCart} />
          {this.props.cartNum != 0 ? <p>{this.props.cartNum}</p> : null}
        </div>
        <div
          className={
            "mobile-basket-info" + (this.state.infoActive ? " active" : "")
          }
        >
          <p>Товар добавлен в вашу корзину</p>
        </div>
      </a>
    );
  }
}
export default UiBtnBasket;
