import React, { Component } from 'react';

import './UiBtnTop.scss';

import { Icon } from '@iconify/react';
import chevronUp from '@iconify/icons-zmdi/chevron-up';

class UiBtnTop extends Component {

  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = -82;
    if (window.innerWidth < 991) headerOffset = 0;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <button className={"top-btn" + (this.props.showTopBtn ? " show" : "")} onClick={() => this._scrollToTargetAdjusted('page')}>
        <Icon className="top-btn-icon" icon={chevronUp} />
      </button>
    );
  }
}
export default UiBtnTop;