import React, { Component } from "react";
import ReactDOM from "react-dom";
import Slider, { Range } from "rc-slider";
import { FixedSizeList as List } from 'react-window';

import "./UiCatalog.scss";
import "rc-slider/assets/index.css";

import { Icon } from "@iconify/react";
import alertCircle from "@iconify/icons-zmdi/alert-circle";
import caretDown from "@iconify/icons-zmdi/caret-down";

import bouquetMdImage from "../../assets/images/catalog/bouquet-sm.svg";
import bouquetSmImage from "../../assets/images/catalog/bouquet-md.svg";
import bouquetLgImage from "../../assets/images/catalog/bouquet-lg.svg";
import bouquetXlImage from "../../assets/images/catalog/bouquet-xl.svg";

import UiAddGiftSlider from "../../components/sliders/UiAddGiftSlider";
import UiCatalogCard from "../cards/UiCatalogCard";
import UiGiftCard from "../cards/UiGiftCard";

import Env from "../../services/Env";
import {getEnvOfStorage} from "../../services/EnvContext";
 
class UiCatalog extends Component {
  state = {
    filterNum: 0,
    filterPriceActive: false,
    filterPriceNum: 0,
    filterSizeActive: false,
    filterSizeNum: 0,
    priceValue: [0, 50000],
    filter: { type: 0 },
  };

  constructor(props) {
    super(props);
  }

  _convertImages(_img, _title) {
    let arr = [];
    let resArr = [];
    if (_img != null) arr = _img.split(",");
    arr.map((item, index) => {
      resArr.push({ value: index, alt: _title, src: getEnvOfStorage()?.uploadsUrl + item });
    });
    return resArr;
  }


  _renderList(_array) {
    var count = 1;
    return _array
      .filter((item) => {
        for (var key in this.state.filter) {
          if (item[key] === undefined || item[key] != this.state.filter[key])
            return false;
        }
        return true;
      })
      .map((item, index) => {
          return (
            <UiCatalogCard
              key={index}
              imageList={item.imgs}
              title={item.title}
              price={item.price + " ₽"}
              infoPrice={
                this.props.activeCategory == 1
                  ? "Уточнить цены и сделать заказ можно связавшись с нашим менеджером"
                  : ""
              }
              category={item.category}
              filter={item.type}
              shopClick={() => this.props.shopClick(item)}
              openClick={() => this.props.openClick(item)}
            />
          );
      });
  }


  render() {
    const { priceValue } = this.state;
    const dotStyle = { borderColor: "orange" };

    return (
      <div className="catalog-wrap">
 
        <div className="catalog"> 
            <div className="catalog-category"> 
              {this._renderList(this.props.mainList)}
            </div>
         
        </div>
      </div>
    );
  }
}
export default UiCatalog;
 