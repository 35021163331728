
export const saveUser = (_user) => {
  localStorage.setItem("mfUser", JSON.stringify(_user));
}

function checkIfEmptyValue(value) {
   return ((value !== null) && (value !== undefined) && (value !== '') && (value !== 'undefined'));
}

export const loadUser = () => {
  let _user = null;
  let user = localStorage.getItem("mfUser");
  if (checkIfEmptyValue(user)) {
    //console.log("load user: "+JSON.stringify(user))
    user = JSON.parse(user);
    _user = user;
  }
  return _user;
}


export const isAuthenticated = () => {
  if (localStorage.getItem("mfUser") == null) {
    return false;
  } else {
    return true;
  }
};

export const isOrg = () => {
  let user = localStorage.getItem("mfUser");
  if (checkIfEmptyValue(user)) {
    user = JSON.parse(user);
    if ((user?.user_form_id === 1) || (user?.user_form_id === 2)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }

};


export const logout = () => {
  if (localStorage.getItem("mfUser") != null) {
    localStorage.removeItem("mfUser");
  }
};
