import React, { Component } from "react";

import "./UiGiftCard.scss";

import { Icon } from "@iconify/react";
import eyeIcon from "@iconify/icons-zmdi/eye";
import shoppingCart from "@iconify/icons-zmdi/shopping-cart";
import arrowRight from "@iconify/icons-zmdi/arrow-right";

class UiGiftCard extends Component {
  state = {
    activeSlide: 0,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <div className="gift-card" title={this.props.title}>
        <div className="gift-card-image">
          <div className="gift-card-slider">
            <img src={this.props.image} alt={this.props.title} />
          </div>
          <div className="gift-card-control">
            <button onClick={this.props.shopClick}>
              <Icon icon={shoppingCart} />
            </button>
            <button onClick={this.props.openClick}>
              <Icon icon={eyeIcon} />
            </button>
          </div>
        </div>
        <div className="gift-card-info">
          <h3>{this.props.title}</h3>
          <p>₽ {this.props.price}</p>
        </div>
      </div>
    );
  }
}
export default UiGiftCard;
