import React, { Component } from "react";


import "./UiModal.scss";
import "./UiModalCatalog.scss";


import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-zmdi/close";
import arrowLeft from "@iconify/icons-zmdi/arrow-left";
import arrowRight from "@iconify/icons-zmdi/arrow-right";
import cardGiftcard from "@iconify/icons-zmdi/card-giftcard";

import UiBtnPink from "../buttons/UiBtnPink";
import UiGiftAddCard from "../cards/UiGiftAddCard";
import UiFreeGiftCard from "../cards/UiFreeGiftCard";
import UiModal from "./UiModal";
import UiSwitch from "../forms/UiSwitch";

import { Helmet } from "react-helmet";

import ZoomSlider from 'react-instagram-zoom-slider';
import Env from "../../services/Env";
import {getEnvOfStorage} from "../../services/EnvContext";

class UiModalCatalog extends Component {
  state = {
    activeSlide: 1,
    activeSetting: 0,
    giftSlidePage: 1,
    additionalCart: [],
    additionalPrice: 0,
    giftBoxActive: false,
  };

  constructor(props) {
    super(props);
  }

  _checkSize() {
    let size = 12;
    let width = window.innerWidth;
    if (width < 990 && width > 760) {
      size = 6;
    }
    if (width < 760) {
      size = 4;
    }
    return size;
  }

  _nextSlide() {
    if (
      this._convertImages(this.props.imageList).length >
      this.state.activeSlide + 1
    ) {
      this.setState({ activeSlide: this.state.activeSlide + 1 });
    } else {
      this.setState({ activeSlide: 0 });
    }
  }

  _prevSlide() {
    if (this.state.activeSlide > 0) {
      this.setState({ activeSlide: this.state.activeSlide - 1 });
    } else {
      this.setState({
        activeSlide: this._convertImages(this.props.imageList).length - 1,
      });
    }
  }

  getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  handleTouchStart(evt) {
    const firstTouch = this.getTouches(evt)[0];
    this.setState({ xDown: firstTouch.clientX, yDown: firstTouch.clientY });
  }

  handleTouchMove(evt) {
    if (!this.state.xDown || !this.state.yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this.state.xDown - xUp;
    var yDiff = this.state.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        this._nextSlide();
      } else {
        /* right swipe */
        this._prevSlide();
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this.setState({ xDown: null, yDown: null });
  }

  handleTouchStart2(evt) {
    const firstTouch = this.getTouches(evt)[0];
    this.setState({ xDown: firstTouch.clientX, yDown: firstTouch.clientY });
  }

  handleTouchMove2(evt) {
    if (!this.state.xDown || !this.state.yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this.state.xDown - xUp;
    var yDiff = this.state.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
      } else {
        /* right swipe */
      }
    } else {
      console.log(yDiff)
      if (yDiff > 0) {
        /* up swipe */
      } else  if (yDiff < - 14) {
        /* down swipe */
        this.props.modalClose();
      }
    }
    /* reset values */
    this.setState({ xDown: null, yDown: null });
  }


  componentDidUpdate(prevProps) {
    if (prevProps.modalOpen == false && this.props.modalOpen) {
      this.setState({
        isClear: true,
        additionalPrice: 0,
        additionalCart: [],
        activeSlide: 0,
      });
    }
  }

  _convertImages(_img) {
    let arr = [];
    let resArr = [];
    if (_img != null) arr = _img.split(",");
    arr.map((item, index) => {
      resArr.push({ value: index, alt: "Букет", src: getEnvOfStorage()?.uploadsUrl + item });
    });
    return resArr;
  }

  _convertImagesSmall(_img) {
    let arr = [];
    let resArr = [];
    if (_img != null) arr = _img.split(",");
    arr.map((item, index) => {
      resArr.push({
        value: index,
        alt: "Букет",
        src: getEnvOfStorage()?.uploadsUrl + item.replace(".jpg", "-min.jpg"),
      });
    });
    return resArr;
  }

  calcTotalPrice() {
    return (
      parseFloat(this.props.price) +
      parseFloat(
        this.state.giftBoxActive
          ? this.props.settings.settings_price_giftbox
          : 0
      ) +
      parseFloat(this.state.additionalPrice)
    );
  }

  addAdditionalToCart(val, item) {
    item.count = 1;

    if (val) {
      let arr = this.state.additionalCart;
      arr.push(item);
      this.setState({ additionalCart: arr }, () => {
        let p = 0;
        this.state.additionalCart.map((_item, _index) => (p = p + _item.price));
        this.setState({ additionalPrice: p });
      });
    } else {
      let kk = 0;
      this.state.additionalCart.map((_item, _index) =>
        item.id == item.id ? (kk = _index) : null
      );
      let arr = this.state.additionalCart;
      arr.splice(kk, 1);
      this.setState({ additionalCart: arr }, () => {
        let p = 0;
        this.state.additionalCart.map((_item, _index) => (p = p + _item.price));
        this.setState({ additionalPrice: p });
      });
    }
  }

  hasInCart(id) {
    let fl = false;
    this.props.cart.map((item) => {
      if (id == item.id) fl = true;
    });
    return fl;
  }

  toCart() {
    let arr = [];
    let _item = this.props.item;
    if (_item.category == 1) _item.price = 0;
    _item.count = 1;
    _item.color = this.state.activeSetting;
    _item.giftbox = this.state.giftBoxActive;
    if (this.state.additionalCart.length > 0) {
      arr = arr.concat(this.state.additionalCart);
    }
    if (this.hasInCart(_item.id)) {
      _item.count = _item.count + 1;
    } else {
      arr = arr.concat(_item);
    }
    return arr;
  }


  render() {
    var slideImageList = this._convertImages(this.props.imageList).map(
      (item, index) => {
        return (
          <img src={item.src} alt={item.alt} draggable={false} />
        );
      }
    );

    var slideImageListDesktop = this._convertImages(this.props.imageList).map(
      (item, index) => {
        return (
          <div
            className={
              "modal-catalog-image-slide" +
              (index == this.state.activeSlide ? " active" : "")
            }
            key={index}
          >
            <img src={item.src} alt={item.alt} />
          </div>
        );
      }
    );



    var controlsList = this._convertImages(this.props.imageList).map(
      (item, index) => {
        return (
          <button
            className={
              "slide-btn" + (index == this.state.activeSlide ? " active" : "")
            }
            key={index}
          ></button>
        );
      }
    );

    var giftsList = this.props.giftsList.map((item, index) => {
      if (
        this.state.giftSlidePage * this._checkSize() - this._checkSize() <
        index &&
        index < this.state.giftSlidePage * this._checkSize()
      ) {
        return (
          <UiGiftAddCard
            key={index}
            imageList={this._convertImagesSmall(item.imgs)}
            title={item.title}
            price={item.price}
            click={(val) => {
              this.addAdditionalToCart(val, item);
            }}
          />
        );
      }
    });

    var colors = this.props.colors.map((item, index) => {
      return (
        <button
          key={index}
          className={
            this.state.activeSetting == item.split("-")[1] ? "active" : ""
          }
          style={{ backgroundColor: item.split("-")[1] }}
          onClick={() => this.setState({ activeSetting: item.split("-")[1] })}
        ></button>
      );
    });

    const modalInner = (
      <div className="modal-catalog">
        <Helmet>

          {this.props.title ? <title>{this.props.title} от MATE flowers</title> : null}
          {this.props.item.category == 0 || this.props.item.category == 1 || this.props.item.category == 2 ? <meta name="description" content={"Авторкий " + this.props.title + " MATE flowers, доставка по москве и МО"} /> : null}
          {this.props.item.category == 3 ? <meta name="description" content={"Заказать " + this.props.title + " с доставкой по москве и мо"} /> : null}
          {this.props.item.category == 4 ? <meta name="description" content={"Заказать " + this.props.title + " с доставкой по москве и мо "} /> : null}
          {this.props.item.category == 5 ? <meta name="description" content={"Цветочная " + this.props.title + " от MATE flowers. Новая неделя - новый букет! "} /> : null}
          {this.props.item.category == 6 ? <meta name="description" content={this.props.title + " для вашего мероприятия от MATE flowers"} /> : null}
          {this.props.item.category == 7 ? <meta name="description" content={this.props.title + " от MATE flowers, доставка по москве и МО"} /> : null}

          {this.props.item.category == 0 || this.props.item.category == 1 || this.props.item.category == 2 ? <meta name="keywords" content={"Купить " + this.props.title + " от MATE flowers, купить цветы Москва"} /> : null}
          {this.props.item.category == 3 ? <meta name="description" content={"Доставка " + this.props.title + " от MATE flowers, купить цветы и подарки москва"} /> : null}
          {this.props.item.category == 4 ? <meta name="description" content={"цветочный мастер-класс москва, сертификат на мастер-класс, подарочный сертификат "} /> : null}
          {this.props.item.category == 5 ? <meta name="description" content={"Купить " + this.props.title + "от MATE flowers, купить цветы Москва"} /> : null}
          {this.props.item.category == 6 ? <meta name="description" content={"цветочная " + this.props.title + " с доставкой от MATE flowers, доставка цветов москва"} /> : null}
          {this.props.item.category == 7 ? <meta name="description" content={"заказать " + this.props.title + " от MATE flowers, цветочный декор москва"} /> : null}

          <meta name="keywords" content={"Купить " + this.props.title + " от MATE flowers, купить цветы Москва"} />
        </Helmet>

        <div className="modal-body">
          <button
            className="modal-close-btn"
            onClick={() => this.props.modalClose()}
          >
            <Icon className="modal-close-btn-icon" icon={closeIcon} />
          </button>
          <div className="modal-catalog-top">
            <div className="modal-catalog-image">

              <div 
                className="modal-catalog-slider-zoom mobile-slider " 
                onTouchStart={(ev) => this.handleTouchStart2(ev)}
                onTouchMove={(ev) => this.handleTouchMove2(ev)}
              >
                <ZoomSlider activeDotColor="#db3762" slides={slideImageList} />
              </div>

              <div 
                className="modal-catalog-slider desktop-slider " 
                onTouchStart={(ev) => this.handleTouchStart(ev)}
                onTouchMove={(ev) => this.handleTouchMove(ev)}
              >
                {slideImageListDesktop}
                <div className="catalog-slider-controls">{controlsList}</div>
              </div>

              {this._convertImages(this.props.imageList).length == 1 ? null : (
                <div className="modal-catalog-control  desktop-slider">
                  <button onClick={() => this._prevSlide()}>
                    <Icon icon={arrowLeft} />
                  </button>
                  <button onClick={() => this._nextSlide()}>
                    <Icon icon={arrowRight} />
                  </button>
                </div>
              )}


            </div>
            <div className="modal-catalog-info">
              <div className="modal-catalog-info-settings">
                {this.props.filter != 0 ? (
                  <div className="filter-label">
                    <span>
                      {this.props.filter == 1
                        ? "Новинка"
                        : this.props.filter == 2
                          ? "Limited"
                          : this.props.filter == 3
                            ? "Лидер продаж"
                            : null}
                    </span>
                  </div>
                ) : null}
                <h3>{this.props.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: this.props.description }} ></p>
                <p>Состав: {this.props.composition}</p>
                {colors.length > 0 ? (
                  <div className="catalog-setting">
                    <p>Модификации</p>
                    <div className="setting-item">
                      <p>Цвет оберточной бумаги:</p>
                      <div className="settings-list">{colors}</div>
                    </div>
                  </div>
                ) : null}
                {this.props.item.category == 3 || this.props.hideSpecialInfo ? null : (
                  <div className="modal-catalog-gifts-info modal-catalog-gifts-info-indent">
                    <h3>Заказав этот букет, вы получите в подарок:</h3>
                    <div className="modal-catalog-gifts-wrap">
                      <UiFreeGiftCard
                          image={require("../../assets/images/gifts/free-1.jpg")}
                          title="Бесплатная открытка"
                      />
                      <UiFreeGiftCard
                           image={require("../../assets/images/gifts/free-4.jpg")}
                          title="Подкормка для цветов"
                      />
                      <UiFreeGiftCard
                          image={require("../../assets/images/gifts/free-2.jpg")}
                          title="Инструкция к букету"
                      />
                      <UiFreeGiftCard
                          image={require("../../assets/images/gifts/free-3.jpg")}
                          title="Упаковка и аквабокс"
                      />
                    </div>
                  </div>
                )}
                {/*this.props.item.category == 3 ? (
                  <div className="switch-item-wrap">
                    <UiSwitch
                      labelEnabled="Завернуть в подарочную упаковку"
                      labelEnabledBold={
                        "+ " + this.props.settings.settings_price_giftbox + " ₽"
                      }
                      labelDisabled="Не заворачивать в подарочную упаковку"
                      clear={this.state.isClear}
                      clearCallBack={() => this.setState({ isClear: false })}
                      callBack={(val) => {
                        this.setState({ giftBoxActive: val });
                      }}
                    />
                  </div>
                    ) : null*/}
              </div>
              <div className="modal-catalog-info-price">
                <p>
                  <Icon icon={cardGiftcard} />{" "}
                  <span>
                    Вы получите{" "}
                    {(this.props.price *
                      (this.props.settings.settings_percent_to_bonus /
                        100)).toFixed(2)}{" "}
                    бонусов
                  </span>
                </p>
                {this.props.item.category == 1 ? (
                  <UiBtnPink
                    btnText="Связаться с менеджером"
                    href={
                      "https://wa.me/+79951168635?text=Доброго%20времени%20суток!%20Хочу%20сделать%20заказ%20букета%20для%20невесты%20" +
                      this.props.title.replace(/ #/gi, "%20") +
                      "."
                    }
                  />
                ) : (
                  <UiBtnPink
                    btnText={
                      "Добавить в корзину за " + this.calcTotalPrice() + " ₽"
                    }
                    onClick={() => this.props.onClick(this.toCart())}
                  />
                )}
              </div>
              {this.props.hideSpecialInfo ? null :
                <div className="modal-catalog-gifts-info modal-catalog-gifts-info-indent">
                  <div className="delivery-info-text">
                    <h3>Соответствие букета!</h3>
                    <p>
                      Мы сделали удобный сайт с каталогом букетов, для того чтобы
                      был понятен стиль, размер и цветовое настроение наших
                      букетов.
                    </p>
                    <p>
                      К сожалению, мы не можем гарантировать 100% копию букета,
                      ежедневно в нашу мастерскую приезжают цветочки с разных
                      уголков света, и они имеют свойство отличаться. Со своей
                      стороны, мы гарантируем соблюсти основной состав и стиль
                      вашего букета, в этом вы можете не сомневаться 😉
                    </p>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <UiModal
        modal="catalog"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}
export default UiModalCatalog;
