import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Redirect } from "react-router";

import "./UiHeader.scss";

import { Icon } from "@iconify/react";
import phoneInTalk from "@iconify/icons-zmdi/phone-in-talk";
import menuIcon from "@iconify/icons-zmdi/menu";
import closeIcon from "@iconify/icons-zmdi/close";
import pinDrop from "@iconify/icons-zmdi/pin-drop";

import iconCheck from "../../assets/images/header/icon-check-white.svg";
import iconGift from "../../assets/images/header/icon-gift-white.svg";
import logoInstagram from "../../assets/images/header/logo-instagram.svg";
import logoTelegram from "../../assets/images/header/logo-telegram.svg";
import logoWhatsapp from "../../assets/images/header/logo-whatsapp.svg";
import logoInstagramBlack from "../../assets/images/header/logo-instagram-black.svg";
import logoTelegramBlack from "../../assets/images/header/logo-telegram-black.svg";
import logoWhatsappBlack from "../../assets/images/header/logo-whatsapp-black.svg";
import appStoreIcon from "../../assets/images/header/app-store.svg";
import googlePlayIcon from "../../assets/images/header/google-play.svg";
import appStoreIconBlack from "../../assets/images/header/app-store-black.svg";
import googlePlayIconBlack from "../../assets/images/header/google-play-black.svg";
import logoImage from "../../assets/images/header/logo2.jpg";
import logoImageSmall from "../../assets/images/header/logo-small2.png";

import { ReactComponent as SvgYandexLogo } from "../../assets/images/header/yandex.svg";
import { ReactComponent as SvgGoogleLogo } from "../../assets/images/header/google.svg";
import { ReactComponent as SvgLogoTelegram } from "../../assets/images/header/logo-telegram-black.svg";
import { ReactComponent as SvgLogoWhatsapp } from "../../assets/images/header/logo-whatsapp-black.svg";
import { ReactComponent as SvgLogoInstagram } from "../../assets/images/header/icons8-instagram.svg";
import { ReactComponent as SvgArrowDown } from "../../assets/images/ui/select-arrow-black.svg";

import UiBtnLight from "../buttons/UiBtnLight";
import UiBtnPink from "../buttons/UiBtnPink";
import UiPlaceSelect from "../forms/UiPlaceSelect";

import { storeData } from "../../services/Storage";
import MainConstant from "../../constants/MainConstant";
import { getSpecialChapter, getAllCategories } from "../../services/Settings";

class UiHeader extends Component {
  state = {
    activeMobile: false,
    menu1Drop: false,
    menu2Drop: false,
    redirect: false,
    redirectPage: "",
    specialChapterTitle: null,

    menuList: [],
  };

  constructor(props) {
    super(props);

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
    });
  }

  componentDidMount() {
    getSpecialChapter(8).then((res) => {
      if (res.response.length > 0) {
        if (res.response[0].is_block == 0) {
          storeData("special", res.response[0].title);
          this.setState({ specialChapterTitle: res.response[0].title });
        } else {
          storeData("special", null);
          this.setState({ specialChapterTitle: null });
        }
      } else {
        storeData("special", null);
        this.setState({ specialChapterTitle: null });
      }
    });

    getAllCategories().then((res) => {
      let arr = [];
      res.response.reverse().map((item) => {
        if (item.is_block == 0 && item.title_url != null) {
          arr.push(item);
        }
      });
      storeData("menuList", arr);
      this.setState({ menuList: arr });
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  navigate = (value) => {
    this.props.history.push(value);
  };

  _openMobileMenu = () => {
    let top = this.state.topOffset;
    this.setState({ activeMobile: true, topScrollConst: top });
    let element = document.getElementById("page");
    ReactDOM.findDOMNode(element).style.top = -top + "px";
    ReactDOM.findDOMNode(element).style.position = "fixed";
  };

  _closeMobileMenu = () => {
    let element = document.getElementById("page");
    ReactDOM.findDOMNode(element).style.position = "static";
    this.setState({ activeMobile: false }, () => {
      window.scrollTo({
        top: this.state.topScrollConst,
      });
    });
  };

  _scrollToTargetAdjusted(target) {
    this.setState({ activeMobile: false });
    var element = document.getElementById(target);
    var headerOffset = -82;
    if (window.innerWidth < 991) headerOffset = -82;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  render() {
    let menuLinks = this.state.menuList.map((item, index) => {
      return (
        <li
          key={index}
          className={
            "nav-menu-item" +
            (this.props.page == item.title_url ? " active" : "")
          }
        >
          <a href={`/special/${item.title_url}`}>{item.title}</a>
        </li>
      );
    });

    if (this.state.redirect) {
      return <Redirect push to={"/" + this.state.redirectPage} />;
    }
    return (
      <header className="header-wrap">
        {/* header info */}
        <div className="head-info">
          <div className="wrapper">
            <div className="head-info-about">
              <div className="about-item">
                <img src={iconCheck} alt="Свежие поставки цветов каждое утро" />
                <p>
                  <span>Свежие поставки</span> каждое утро
                </p>
              </div>
              <div className="about-item">
                <img src={iconGift} alt="Подарок при покупке букета" />
                <p>
                  <span>Подарки</span> к букетам
                </p>
              </div>
            </div>
            <div className="head-info-contact">
              <div className="contact-item">
                <a href="tel:84951083299">
                  <span>
                    <Icon className="contact-item-call" icon={phoneInTalk} />
                  </span>
                  <span>8 (495) 108-32-99</span>
                </a>
              </div>
              <div className="contact-item">
                <a href="https://t.me/mateflowers_bot">
                  <SvgLogoTelegram
                    className="contact-item-img"
                    title="Telegram MATEflowers"
                  />
                </a>

                <a href={"https://api.whatsapp.com/send?phone="+MainConstant.WP_PHONE+"&text=%D0%94%D0%BE%D0%B1%D1%80%D0%BE%D0%B3%D0%BE%20%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8%20%D1%81%D1%83%D1%82%D0%BE%D0%BA!%20%D0%A5%D0%BE%D1%87%D1%83%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%BF%D0%BE%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D1%83%20%D0%B1%D1%83%D0%BA%D0%B5%D1%82%D0%B0.#"}>
                  <SvgLogoWhatsapp
                    className="contact-item-img"
                    title="Whatsapp MATEflowers"
                  />
                </a>

                <a href="https://www.instagram.com/mateflowers">
                  <img
                    src={logoInstagramBlack}
                    alt="MATEflowers доставка цветов"
                    title="Instagram MATEflowers"
                  />
                </a>
              </div>
              <div className="contact-item">
                <a href="https://play.google.com/store/apps/details?id=ru.mateflowers">
                  <img
                    src={googlePlayIcon}
                    alt="Приложение MATEflowers доставка цветов для Android в Google Play"
                    title="Приложение MATEflowers в Google Play"
                  />
                </a>
                <a href="https://apps.apple.com/ru/app/mate-flowers-gifts/id1567569850">
                  <img
                    src={appStoreIcon}
                    alt="Приложение MATEflowers доставка цветов для iOS в App Store"
                    title="Приложение MATEflowers в App Store"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* header */}
        <div
          className={
            "wrapper header" + (this.props.scrollHeader ? " scroll" : "")
          }
        >
          <div className="header-logo">
            <a href="/">
              <img className="logo-image" src={logoImage} />
              <img className="logo-image-small" src={logoImageSmall} />
            </a>
            <div className="contact-item-mobile">
              <div className="contact-item">
                <a href="https://www.instagram.com/mateflowers">
                  <img
                    src={logoInstagramBlack}
                    alt="MATEflowers доставка цветов"
                    title="Instagram MATEflowers"
                  />
                </a>

                <a href={"https://api.whatsapp.com/send?phone="+MainConstant.WP_PHONE+"&text=%D0%94%D0%BE%D0%B1%D1%80%D0%BE%D0%B3%D0%BE%20%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8%20%D1%81%D1%83%D1%82%D0%BE%D0%BA!%20%D0%A5%D0%BE%D1%87%D1%83%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%BF%D0%BE%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D1%83%20%D0%B1%D1%83%D0%BA%D0%B5%D1%82%D0%B0."}>
                  <SvgLogoWhatsapp
                    className="contact-item-img"
                    title="Whatsapp MATEflowers"
                  />
                </a>
                <a href="https://t.me/mateflowers_bot">
                  <SvgLogoTelegram
                    className="contact-item-img"
                    title="Telegram MATEflowers"
                  />
                </a>
                {this.props.scrollHeader ? (
                  <a href="https://play.google.com/store/apps/details?id=ru.mateflowers">
                    <img
                      src={googlePlayIconBlack}
                      alt="Приложение MATEflowers доставка цветов для Android в Google Play"
                      title="Приложение MATEflowers в Google Play"
                    />
                  </a>
                ) : null}
                {this.props.scrollHeader ? (
                  <a href="https://apps.apple.com/ru/app/mate-flowers-gifts/id1567569850">
                    <img
                      src={appStoreIconBlack}
                      alt="Приложение MATEflowers доставка цветов для iOS в App Store"
                      title="Приложение MATEflowers в App Store"
                    />
                  </a>
                ) : null}
              </div>
              <button
                className="menu-mobile-btn"
                onClick={() => this._openMobileMenu()}
              >
                <Icon icon={menuIcon} />
              </button>
            </div>

            <div className="header-about">
              <UiPlaceSelect />

              <p className="delivery-time">Время доставки ~ 180 минут</p>
              <div className="rating">
                <a
                  href="https://www.google.com/search?q=mateflower&oq=mate&aqs=chrome.1.69i60j69i59j69i57j0i433j69i60l2j69i61j69i65.2185j0j1&sourceid=chrome&ie=UTF-8#lrd=0x46b54b6df744d0d3:0x3b29701d872b298,1,,,"
                  target="_blank"
                >
                  <SvgGoogleLogo width={12} height={12} />
                  <span> 4,9 (500+)</span>
                </a>
                <a
                  href="https://yandex.ru/maps/org/mate_flowers/171305059293/reviews/"
                  target="_blank"
                >
                  <SvgYandexLogo width={12} height={12} />
                  <span> 5,0 (650+)</span>
                </a>
              </div>
            </div>
          </div>
          {/* profile */}
          <div className="header-profile">
            <div className="profile-basket">
              {this.props.page != "basket" ? (
                <UiBtnPink
                  href="/basket"
                  btnText="Корзина"
                  btnNum={this.props.basketNum}
                />
              ) : null}
            </div>
            {this.props.page != "profile" ? (
              <div className="profile-login">
                {this.props.isLogin ? (
                  <UiBtnLight
                    btnText="Профиль"
                    onClick={() => this.props.profileClick()}
                  />
                ) : (
                  <UiBtnLight
                    btnText="Войти"
                    onClick={() => this.props.loginClick()}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
        {/* nav menu */}
        <div
          className={
            "nav-menu-wrap" +
            (this.props.scrollHeader ? " scroll" : "") +
            (this.state.activeMobile ? " active-mobile" : "")
          }
        >
          <div className="nav-menu-mobile">
            <div className="nav-menu-mobile-title">
              <h2>MATE flowers</h2>
              <button className="btn" onClick={() => this._closeMobileMenu()}>
                <Icon icon={closeIcon} />
              </button>
            </div>
            <div className="nav-menu-mobile-delivery">
              <p>
                <Icon icon={pinDrop} className="delivery-icon" />{" "}
                <span>Доставка по Москве ~ 180 мин</span>
              </p>
              <div className="rating-mobile">
                <a
                  href="https://www.google.com/search?q=mateflower&oq=mate&aqs=chrome.1.69i60j69i59j69i57j0i433j69i60l2j69i61j69i65.2185j0j1&sourceid=chrome&ie=UTF-8#lrd=0x46b54b6df744d0d3:0x3b29701d872b298,1,,,"
                  target="_blank"
                >
                  <SvgGoogleLogo width={12} height={12} />
                  <span> 4,9 (500+)</span>
                </a>
                <a
                  href="https://yandex.ru/maps/org/mate_flowers/171305059293/reviews/"
                  target="_blank"
                >
                  <SvgYandexLogo width={12} height={12} />
                  <span> 5,0 (650+)</span>
                </a>
              </div>
            </div>
          </div>
          <nav className="wrapper nav-menu">
            <div className="nav-menu-container">
              <a href="/">
                <img className="logo-image" src={logoImage} />
                <img className="logo-image-small" src={logoImageSmall} />
              </a>
              <div className="nav-menu-left">
                <ul className="nav-menu-list">
                  <li
                    className={
                      "nav-menu-item" +
                      (this.props.page == "home" ? " active" : "")
                    }
                  >
                    <a href="/">Букеты</a>
                  </li>

                  <li
                    className={
                      "nav-menu-item" +
                      (this.props.page == "gifts" ? " active" : "")
                    }
                  >
                    <a href="/gifts">Подарки</a>
                  </li>

                  {menuLinks}

                  <li
                    className={
                      "nav-menu-item dropdown-menu-item" +
                      (this.props.page == "gifts" ? " active" : "")
                    }
                    onClick={() =>
                      this.setState({ menu1Drop: !this.state.menu1Drop })
                    }
                  >
                    <button>Услуги</button>
                    <SvgArrowDown width={8} heigh={8} />
                    <ul
                      className={
                        "dropdown-menu-inner-list" +
                        (this.state.menu1Drop ? " active" : "")
                      }
                    >
                      <li>
                        <a href="/classes">Мастер классы</a>
                      </li>
                      <li>
                        <a href="/subscribe">Подписка на цветы</a>
                      </li>
                      <li>
                        <a href="/events">Оформление мероприятий</a>
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul className="nav-menu-list">
                  <li
                    className={
                      "nav-menu-item" +
                      (this.props.page == "about" ? " active" : "")
                    }
                  >
                    <a href="/about">О нас</a>
                  </li>
                  <li
                    className={
                      "nav-menu-item" +
                      (this.props.page == "delivery" ? " active" : "")
                    }
                  >
                    <a href="/delivery">Доставка и оплата</a>
                  </li>
                  <li
                    className={
                      "nav-menu-item dropdown-menu-item" +
                      (this.props.page == "gifts" ? " active" : "")
                    }
                    onClick={() =>
                      this.setState({ menu2Drop: !this.state.menu2Drop })
                    }
                  >
                    <button>Информация</button>
                    <SvgArrowDown width={8} heigh={8} />
                    <ul
                      className={
                        "dropdown-menu-inner-list" +
                        (this.state.menu2Drop ? " active" : "")
                      }
                    >
                      {/*<li><a href="/">Возврат товара</a></li>*/}
                      <li>
                        <a href="/loyalty">Программа лояльности</a>
                      </li>
                      {/*<li><a href="/">Пользовательское соглашение</a></li>*/}
                      <li>
                        <a href="/policy">Политика конфиденциальности</a>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      "nav-menu-item" +
                      (this.props.page == "contacts" ? " active" : "")
                    }
                  >
                    <a href="/contacts">Контакты</a>
                  </li>
                  {this.state.activeMobile ? (
                    this.props.isLogin ? (
                      <li className="nav-menu-item">
                        <a onClick={() => this.props.profileClick()}>Профиль</a>
                      </li>
                    ) : (
                      <li className="nav-menu-item">
                        <a onClick={() => this.props.loginClick()}>Войти</a>
                      </li>
                    )
                  ) : null}
                </ul>
              </div>
              {this.props.page != "basket" ? (
                <div className="nav-menu-basket">
                  <UiBtnPink
                    href="/basket"
                    btnText="Корзина"
                    btnNum={this.props.basketNum}
                  />
                </div>
              ) : null}
            </div>
          </nav>
          <div className="nav-menu-mobile">
            <div className="nav-menu-mobile-links">
              <a href="tel:84951083299">
                <p>
                  <Icon icon={phoneInTalk} className="mobile-phone-icon" />{" "}
                  <span>8 495 108-32-99</span>
                </p>
              </a>
              <div>
                <a href="https://play.google.com/store/apps/details?id=ru.mateflowers">
                  <img
                    src={require("../../assets/images/footer/google-play-badge-ru.svg")}
                  />
                </a>
                <a href="https://apps.apple.com/ru/app/mate-flowers-gifts/id1567569850">
                  <img
                    src={require("../../assets/images/footer/app-store-badge-ru.svg")}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default UiHeader;
