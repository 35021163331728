import React, { Component } from "react";

import "./Delivery.scss";

import { Icon } from "@iconify/react";
import thumbsUp from "@iconify/icons-fa-solid/thumbs-up";
import flowerAlt from "@iconify/icons-zmdi/flower-alt";

import aboutImage from "../../assets/images/about/about-image.jpg";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiPageTitle from "../../components/header/UiPageTitle";

import { Helmet } from "react-helmet";

import { storeData, retrieveData, getBasketNum } from "../../services/Storage";
import { getSettings, getDeliveryPrices } from "../../services/Settings";
import { GetFlowersByCategory } from "../../services/Flowers";

class DeliveryPage extends Component {
  state = {
    showTopBtn: false,
    scrollHeader: false,
    basketNum: 0,
    modalLoginActive: false,
    user: null,

    settings: {},
    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },

    cart: [],
    mainList: [],
    giftsList: [],
    waifuList: [],
    manList: [],
    deliverySettings: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });
  }

  _load() {
    let _cart = retrieveData("cart");
    let _user = retrieveData("mfUser");
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: getBasketNum(_cart) });
    }
    if (_user) {
      this.setState({ user: _user });
    }
    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });

    GetFlowersByCategory(0).then((res) => {
      this.setState({ mainList: res.response });
      console.log(res);
    });
    GetFlowersByCategory(1).then((res) => {
      this.setState({ waifuList: res.response });
    });
    GetFlowersByCategory(2).then((res) => {
      this.setState({ manList: res.response });
    });
    GetFlowersByCategory(3).then((res) => {
      this.setState({ giftsList: res.response });
    });

    getDeliveryPrices().then((res) => {
      if (res.response) this.setState({ deliverySettings: res.response })
    });

  }

  addToCart(_cart) {
    this.setState({ basketNum: getBasketNum(_cart) });
    storeData("cart", _cart);
  }

  render() {
    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>Доставка MATE flowers</title>
          <meta name="description" content="Условия доставки MATE flowers" />
          <meta name="keywords" content="Доставка букетов Москва, купить цветы Москва" />
        </Helmet>
        <div className="page-wrapper">
          {/* delivery header */}
          <section className="section about-header">
            <UiHeader
              callBack={(value) => { }}
              page="delivery"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>
          {/* delivery title */}
          <section className="section about-title about-title-bordered">
            <UiPageTitle
              backImg={require("../../assets/images/header/photo-2019-09-11-13-26-12_1x.jpg")}
              pageLink="/delivery"
              pageName="Доставка"
              title="Доставка и Самовывоз по Москве и Московской области"
            />
          </section>
          {/* delivery catalog */}
          <section className="section about">
            <div className="wrapper">
              <div className="delivery-wrap">
                <div className="delivery-info">
                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>САМОВЫВОЗ</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>0₽</p>
                    </div>
                    <p>
                      м. Кропоткинская, ул. Серафимовича, д. 2, с 08.00 до 20.00
                    </p> 
                    <p>
                      м. Электрозаводская, пл. Журавлева, д.10, стр. 4, с 08.00 до 21.00
                    </p>
                  </div>
                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка по Москве 3-х часовой интервал</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>{this.state.deliverySettings[1]?.price}₽</p>
                    </div>
                    <p>В пределах МКАД. По режиму работы магазина.</p>
                  </div>

                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка по Москве в часовой интервал</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>{this.state.deliverySettings[0]?.price}₽</p>
                    </div>
                    <p>В пределах МКАД. По режиму работы магазина.</p>
                  </div>
                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка по Москве к точному времени</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>{this.state.deliverySettings[7]?.price}₽</p>
                    </div>
                    <p>В пределах МКАД. По режиму работы магазина.</p>
                  </div>
                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка до 5 км за МКАД</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>{this.state.deliverySettings[2]?.price}₽</p>
                    </div>
                    <p>
                      Доставка в 3-х часовой интервал по режиму работы магазина.
                    </p>
                  </div>
                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка от 5 до 10 км за МКАД </h3>
                      <div className="delivery-item-title-line"></div>
                      <p>{this.state.deliverySettings[3]?.price}₽</p>
                    </div>
                    <p>
                      Доставка в 3-х часовой интервал по режиму работы магазина.
                    </p>
                  </div>

                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка от 10 до 15 км за МКАД</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>{this.state.deliverySettings[4]?.price}₽</p>
                    </div>
                    <p>
                      Доставка в 3-х часовой интервал по режиму работы магазина.
                    </p>
                  </div>

                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка от 15 до 20 км за МКАД</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>{this.state.deliverySettings[5]?.price}₽</p>
                    </div>
                    <p>
                      Доставка в 3-х часовой интервал по режиму работы магазина.
                    </p>
                  </div>


                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка от 20 до 25 км за МКАД</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>{this.state.deliverySettings[6]?.price}₽</p>
                    </div>
                    <p>
                      Доставка в 3-х часовой интервал по режиму работы магазина.
                    </p>
                  </div>


                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>Доставка c 21:00 до 00:00 в пределах МКАД</h3>
                      <div className="delivery-item-title-line"></div>
                      <p>600₽</p>
                    </div>
                    <p>По 100% предоплате.</p>
                  </div>
                  <div className="delivery-item">
                    <div className="delivery-item-title">
                      <h3>
                        Доставка c 21 до 22:00, с 22 до 23:00 и с 23 до 00:00 в
                        пределах МКАД
                      </h3>
                      <div className="delivery-item-title-line"></div>
                      <p>900₽</p>
                    </div>
                    <p>По 100% предоплате.</p>
                  </div>
                </div>

                <div className="delivery-info-text">
                  <h3>Режим работы магазина с 9:00 до 21:00</h3>
                  <p>
                    Мы обрабатываем заказы с 9.00 до 21.00. Заказы, созданные после 19.30 переносятся на следующий день. При оформлении заказа в ночное время, букет может быть доставлен не ранее 11:00 следующего дня. При срочной доставке мы постараемся доставить букет, как можно скорее, точное время зависит от загруженности магазина и дорог 🌿
                  </p>
                  <p>
                    Внимание! Курьер после приезда на место ожидает получателя не более 25 минут. В случае отсутствия получателя, букет по предварительной договоренности можно оставить у знакомых, соседей или вернуть обратно в офис (в этом случае доставка оплачивается повторно).
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* delivery footer */}
          <section className="section classes-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiBtnBasket
          cartNum={this.state.basketNum}
          infoActive={this.state.infoActive}
          callBack={() => this.setState({ infoActive: false })}
        />

        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.props.history.push("/profile");
          }}
        />
      </div>
    );
  }
}

export default DeliveryPage;
