import React, { Component } from "react";

import "./Classes.scss";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiCatalogFilter from "../../components/catalog/UiCatalogFilter";
import UiCatalogCard from "../../components/cards/UiCatalogCard";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalCatalog from "../../components/modals/UiModalCatalog";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiPageTitle from "../../components/header/UiPageTitle";

import { Helmet } from "react-helmet";

import { storeData, retrieveData, getBasketNum } from "../../services/Storage";
import { getSettings } from "../../services/Settings";
import { GetFlowersByCategory } from "../../services/Flowers";

class SubscribePage extends Component {
  state = {
    showTopBtn: false,
    scrollHeader: false,
    basketNum: 0,
    modalCatalogActive: false,
    modalLoginActive: false,
    user: null,

    settings: {},
    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },

    cart: [],
    classesList: [],
    giftsList: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });
  }

  _load() {
    let _cart = retrieveData("cart");
    let _user = retrieveData("mfUser");
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: getBasketNum(_cart) });
    }
    if (_user) {
      this.setState({ user: _user });
    }
    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });

    GetFlowersByCategory(5).then((res) => {
      this.setState({ classesList: res.response, loader: false }, () => this._openFlower(res.response, this.props.match.params.id));
    });
  }

  hasInCart(id) {
    let fl = false;
    this.state.cart.map((item) => {
      if (id == item.id) fl = true;
    });
    return fl;
  }

  _openFlower(arr, id = null) { 
    if (id) {
      arr.map((item) => { 
        if (item.id == id || item.title_url ==  id) {
          this.setState({
            modalCatalogActive: true,
            selectedItem: item,
          });
        }
      })
    }
  }

  _toCart(item) {
    let arr = this.state.cart;
    let _item = item;
    if (_item.category == 1) _item.price = 0;
    _item.count = 1;
    _item.color = 0;
    _item.giftbox = false;
    if (this.hasInCart(_item.id)) {
      let index = 0;
      arr.map((arrItem, arrIndex) => {
        if (arrItem.id == item.id) index = arrIndex;
      });
      arr[index].count = arr[index].count + 1;
    } else {
      arr = arr.concat(_item);
    }
    return arr;
  }


  addToCart(_cart) {
    this.setState({ basketNum: getBasketNum(_cart), cart: _cart });
    storeData("cart", _cart);
  }

  render() {
    var list = this.state.classesList.map((item, index) => {
      return (
        <UiCatalogCard
          key={index}
          imageList={item.imgs}
          title={item.title}
          price={"₽ " + item.price}
          shopClick={() => {
            this.addToCart(this._toCart(item));
          }}
          openClick={() => { 
            this.props.history.push(`/subscribe/${item.title_url}`);
            this._openFlower(this.state.classesList, item.title_url)
          }}
          category="0"
          filter={item.type}
        />
      );
    });

    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>Цветочная подписка от MATE flowers</title>
          <meta name="description" content="В нашей мастерской можно оформить Цветочную Подписку. Новая неделя - новый букет! " />
          <meta name="keywords" content="цветочная подписка москва, доставка цветов москва" />
        </Helmet>
        <div id="wrapper" className="page-wrapper">
          {/* gifts header */}
          <section className="section classes-header">
            <UiHeader
              callBack={(value) => { }}
              page="subscribe"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>
          <UiCatalogFilter />
          {/* page title */}
          <section className="section classes-title">
            <UiPageTitle
              backImg={require("../../assets/images/header/bg-breacrum.jpg")}
              pageLink="/subscribe"
              pageName="Подписка на цветы"
              title="Подписка на цветы"
            />
          </section>
          {/* gifts catalog */}
          <section className="section classes-catalog">
            <div className="wrapper">
              <div className="classes-catalog-wrap">
                <p>
                  В нашей мастерской можно оформить Цветочную Подписку на
                  месяц🌿
                </p>
                <p>
                  Каждую неделю цветочной подписки мы будем доставлять вам букет
                  из свежих сезонных цветов, новая неделя- новый букет :).
                </p>
                <p>
                  Мы соберем букет в нашем стиле, если у вас есть пожелания по
                  цветовой гамме - укажите их в комментарии к заказу
                </p>
                <br />
                <p>1. Выберите подходящий план подписки. </p>
                <p>
                  2. При оформлении заказа в комментарии напишите удобные даты /
                  время и адрес(а) для доставок и желаемые цветовые гаммы.
                </p>
                <p>
                  3. После оформления с вами свяжется менеджер для согласования
                  доставок.
                </p>
                <p>
                  4. Радуйте себя и близких свежими цветами дома каждую неделю
                  ❤️
                </p>
                <br />
                <div className="classes-cards">{list}</div>
              </div>
            </div>
          </section>
          {/* gifts footer */}
          <section className="section classes-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiBtnBasket
          cartNum={this.state.basketNum}
          infoActive={this.state.infoActive}
          callBack={() => this.setState({ infoActive: false })}
        />

        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.props.history.push("/profile");
          }}
        />

        <UiModalCatalog
          modalOpen={this.state.modalCatalogActive}
          modalClose={() => { 
            this.props.history.push(`/subscribe`);
            this.setState({ modalCatalogActive: false }); 
          }}
          imageList={this.state.selectedItem.imgs}
          item={this.state.selectedItem}
          title={this.state.selectedItem.title}
          filter="1"
          description={this.state.selectedItem.description}
          composition={this.state.selectedItem.composition}
          giftsList={this.state.giftsList}
          colors={
            this.state.selectedItem.modifications != null
              ? this.state.selectedItem.modifications
                .split(",")
                .filter((elem, index, self) => {
                  return index === self.indexOf(elem);
                })
              : []
          }
          price={this.state.selectedItem.price}
          cart={this.state.cart}
          settings={this.state.settings}
          onClick={(cart) => {
            this.addToCart(this._toCart(cart));

            this.setState({ modalCatalogActive: false });
          }}
        />
      </div>
    );
  }
}

export default SubscribePage;
