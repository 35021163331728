import React, { Component } from "react";

import "./UiAddGiftSlider.scss";

import { Icon } from "@iconify/react";
import chevronLeft from "@iconify/icons-zmdi/chevron-left";
import chevronRight from "@iconify/icons-zmdi/chevron-right";

import UiAddGiftSliderCard from "../cards/UiAddGiftSliderCard";

import Env from "../../services/Env";
import UiGiftCard from "../cards/UiGiftCard";
import {getEnvOfStorage} from "../../services/EnvContext";

const slideWidth = 240;
 
class UiAddGiftSlider extends Component {
  state = {
    activeSlide: 0,
    maxSlide: false,
    sliderOffset: 0,
    touchstartX : 0,
    touchendX  : 0,
  };

  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.sliderWrap = React.createRef();

  }

  componentDidMount(){
    
    for (var item of document.getElementsByClassName('add-gift-slider')) {
      item.addEventListener('touchstart', e => {
        this.setState({  touchstartX : e.changedTouches[0].screenX });
      });
    }

    for (var item of document.getElementsByClassName('add-gift-slider')) {
      item.addEventListener('touchend', e => {
        this.setState({ touchendX : e.changedTouches[0].screenX });
        this.handleGesture();
      });
    }

   

  }

  handleGesture() {
    if (this.state.touchendX < this.state.touchstartX) this.nextSlide();
    if (this.state.touchendX > this.state.touchstartX)  this.prevSlide();
  }
  

  _convertImages(_img) {
    let arr = [];
    let resArr = [];
    if (_img != null) arr = _img.split(",");
    arr.map((item, index) => {
      resArr.push(getEnvOfStorage()?.uploadsUrl + item.replace(".jpg", "-min.jpg"));
    });
    return resArr.length > 0 ? resArr[0] : null;
  }

  prevSlide() {
    if (this.state.activeSlide > 0) {
      this.setState({ activeSlide: this.state.activeSlide - 1 }, () => {
        this.setState({
          sliderOffset: -this.state.activeSlide * slideWidth,
          maxSlide: false,
        });
      });
    }
  }

  nextSlide() {
    let sliderWidth = this.slider.current.offsetWidth;
    let sliderWrapWidth = this.sliderWrap.current.offsetWidth;
    let maxSlideOffset = sliderWidth - sliderWrapWidth;

    if (
      this.state.activeSlide < this.props.giftsList.length - 1 &&
      this.state.activeSlide * slideWidth < maxSlideOffset
    ) {
      this.setState({ activeSlide: this.state.activeSlide + 1 }, () => {
        if (this.state.activeSlide * slideWidth >= maxSlideOffset) {
          this.setState({ sliderOffset: -maxSlideOffset, maxSlide: true });
        } else {
          this.setState({ sliderOffset: -this.state.activeSlide * slideWidth });
        }
      });
    }
  }

  render() {
    var giftsList = this.props.giftsList.map((item, index) => {
      return (
        <div key={index} className={this.props.homeGifts ? "home-gift-slider-wrap" : "gift-slider-wrap"}>
          {this.props.homeGifts ? (
            <UiGiftCard
              image={this._convertImages(item.imgs)}
              title={item.title}
              price={item.price}
              shopClick={() => this.props.addClick(item)}
              openClick={() => this.props.showClick(item)}
            />
          ) : (
            <UiAddGiftSliderCard
              image={this._convertImages(item.imgs)}
              title={item.title}
              price={item.price}
              addClick={() => this.props.addClick(item)}
              showClick={() => this.props.showClick(item)}
            />
          )}
        </div>
      );
    });

    return (
      <div className={"add-gift-slider" + (this.props.homeGifts ? " home-add-gift-slider" : "")} ref={this.sliderWrap}>
        {this.state.activeSlide != 0 ? (
          <button
            className="add-gift-slider-btn left-btn"
            onClick={() => this.prevSlide()}
          >
            <Icon className="add-gift-slider-btn-icon" icon={chevronLeft} />
          </button>
        ) : null}
        <div
          className="add-gift-slider-wrap"
          ref={this.slider}
          style={{ left: this.state.sliderOffset + "px" }}
        >
          {giftsList}
        </div>
        {!this.state.maxSlide ? (
          <button
            className="add-gift-slider-btn right-btn"
            onClick={() => this.nextSlide()}
          >
            <Icon className="add-gift-slider-btn-icon" icon={chevronRight} />
          </button>
        ) : null}
      </div>
    );
  }
}
export default UiAddGiftSlider;
