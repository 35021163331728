import React, { useState, useEffect } from "react";

import "./UiNotify.scss";


const UiNotify = (props) => {



 

     const options = {
        message: `<p>🌸 ${props.title}</p>`,
        dismiss: props.btn
    }

    function run() {
        let data = localStorage.getItem("MF_NOTICE");  
        if (data !== props.title) {
            show()
        }  
    }

    function dismiss() {
        var notice = document.getElementById('cookie-notice')
        if (notice) notice.parentNode.removeChild(notice)
        localStorage.setItem("MF_NOTICE", props.title); 
    }

    function undismiss() {
        delete window.localStorage.cookieNoticeDismissed
    }

    function show() {
        var $div = document.createElement('div')
        $div.className = 'cookie-notice'
        $div.id = 'cookie-notice'

        var $message = document.createElement('div')
        $message.className = 'cookie-notice-message'
        $message.innerHTML = options.message
        $div.appendChild($message)

        var $dismiss = document.createElement('button')
        $dismiss.innerHTML = options.dismiss
        $dismiss.onclick = dismiss
        $div.appendChild($dismiss) 
        if(document.getElementById("page")) document.getElementById("page").appendChild($div); else  document.body.appendChild($div);
       
    }
 


    useEffect(() => {
       setTimeout(() => run(), 2000 ); 
    }, [])

    return null;
};

export default UiNotify;
