import React from "react";
import './index.css';
import GiftSvg from "../../Svg/HeatFillSvg";
import {useSelector} from "react-redux";
import {selectLoyalty} from "../../Store/Slices/LoyaltySlice";

const UiTotalBonuses = (props) => {
    const {totalBonuses} = useSelector(selectLoyalty);

    return(
        <div className="bonus-gift-panel">
            <div className="mr-05"><GiftSvg /></div>
            <p>Вам доступно <b>{totalBonuses | 0} бонусных рублей</b></p>
        </div>
    )
}

export default UiTotalBonuses;