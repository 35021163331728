import {createSlice} from "@reduxjs/toolkit";

/**
 *
 * @type {Slice<{totalBonuses: number}, {setTotalBonuses: reducers.setTotalBonuses}, string>}
 */
export const loyaltySlice = createSlice({
    name: 'loyalty',
    initialState: {
        totalBonuses: 0,
        appliedBonuses: 0,
        promoCode: '',
        totalDiscount: 0,
    },
    reducers: {
        setTotalBonuses: (state, action) => {
            state.totalBonuses = action.payload;
        },
        setAppliedBonuses: (state, action) => {
            state.appliedBonuses = action.payload;
        },
        setPromoCode: (state, action) => {
            state.promoCode = action.payload;
        },
        setTotalDiscount: (state, action) => {
            state.totalDiscount = action.payload;
        }
    },
})

export const selectLoyalty = (state) => state.loyalty;
export const {setTotalBonuses, setAppliedBonuses, setPromoCode, setTotalDiscount} = loyaltySlice.actions;
export default loyaltySlice.reducer;