import React, { Component } from 'react';

import './UiOrderCard.scss';
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify/icons-zmdi/eye';

class UiOrderCard extends Component {

  state = {
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="order-card">
        <div className="order-card-title">
          <span className={"order-card-status" + (this.props.status != 6 ? " active" : "")}>
            {
              this.props.status == 0 ? "новый" :
                this.props.status == 1 ? "подтвержден" :
                  this.props.status == 2 ? "заказ в работе" :
                    this.props.status == 3 ? "заказ готов" :
                      this.props.status == 4 ? "доставляют" :
                        this.props.status == 5 ? "выполнен" :
                          this.props.status == 6 ? "отменен" : null
            }

          </span>
          <h3>{this.props.date}</h3>
        </div>
        <p>{this.props.info}</p>
        {/*<button className="order-card-open" onClick={this.props.onClick}>
          <Icon className="order-open-icon" icon={eyeIcon} />
              </button>*/}
      </div>
    );
  }
}
export default UiOrderCard;