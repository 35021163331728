import React, { Component } from "react";

import "./UiAddGiftSliderCard.scss";

class UiAddGiftSliderCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="add-gift-slider-card" onClick={this.props.onClick}>
        <div
          className="card-image"
          style={{ backgroundImage: "url(" + this.props.image + ")" }}
          onClick={() => this.props.showClick()}
        ></div>
        <div className="card-info">
          <h3>{this.props.title}</h3>
          <button
            className="card-info-price"
            onClick={() => this.props.addClick() }
          >
            +&nbsp;{this.props.price}&nbsp;₽
          </button>
        </div>
      </div>
    );
  }
}
export default UiAddGiftSliderCard;
