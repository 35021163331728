import { getEnvOfStorage } from "./EnvContext";
import { retrieveData, storeData } from "./Storage";
import { v4 as uuidv4 } from 'uuid';

const CART_ORDER_ID = 'cart_order_id';
const CART = 'cart';

function getHeaders() {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
}

/**
 * Создать нового пользователя в программе лояльности (параметры текущего пользователя)
 * @param user
 * @returns {Promise<any>}
 */
export async function newClient(user) {
    let response = await fetch(getEnvOfStorage()?.apiUrl + '/loyalty/client/add', {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
            client: {
                phoneNumber: user?.phone
            },
            shop: {
                code: "web-shop",
                name: "mateflowers-site"
            }
        })
    });
    return await response.json();
}

/**
 * Обновить параметры клиента (текущего пользователя)
 * @param user
 * @returns {Promise<any>}
 */
export async function updateClient(user) {
    let response = await fetch(getEnvOfStorage()?.apiUrl + `/loyalty/client/update?api_token=${user?.api_token}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
            phoneNumber: user?.phone,
            client: {
                phoneNumber: user?.phone,
                fullName: user?.name,
                email: user?.email,
                isEmailSubscribed: true
            }
        })
    });
    return await response.json();
}


function listProducts(cartItems) {
    let products = [];
    if (cartItems?.length > 0) {
        cartItems.map((item, index) => {
            products.push({
                id: item?.id.toString(),
                product: {
                    sku: item?.id.toString(),
                    blackPrice: item?.price,
                    category: item?.category.toString(),
                },
                qty: item?.count
            });
        });
    }
    return products;
}

function listOrderProducts(cartItems) {
    let products = [];
    if (cartItems?.length > 0) {
        cartItems.map((item, index) => {
            products.push({
                id: item?.id.toString(),
                product: {
                    sku: item?.id.toString(),
                    blackPrice: item?.price,
                    title: item?.title.toString(),
                },
                qty: item?.count
            });
        });
    }
    return products;
}

/**
 * Получить количество ожидаемых и доступных бонусов
 * @param user
 * @returns {Promise<any>}
 */
export async function getBalance(user) {
    let response = await fetch(getEnvOfStorage()?.apiUrl + `/loyalty/client/balance?api_token=${user?.api_token}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
            phoneNumber: user?.phone,
        })
    });
    return await response.json();
}

/**
 * Расчет скидок и бонусов для чека продажи или заказа
 * @param data
 * @param user
 * @returns {Promise<any>}
 */
export async function calculatePurchase(cartItems,  promoCode = null ) {
    let products = listProducts(cartItems);
    let query = { 
        shop: {
            code: "web-shop",
            name: "mateflowers-site"
        },
        rows: products
    };

    /*
    if(user){
        query["client"] = {
            phoneNumber: user?.phone
        } 
    }*/

    if(promoCode){
        query["promocode"] = promoCode
    }
    let response = await fetch(getEnvOfStorage()?.apiUrl + `/loyalty/calc/purchase`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
            calculationQuery: query
        })
    });
    return await response.json();
}

/**
 * Создать/обновить заказ
 * @param cartItems
 * @param {string} orderId
 * @param {int} applyBonuses
 * @param user
 * @param {string} promoCode
 * @returns {Promise<any>}
 */
export async function setOrder(cartItems, orderId, applyBonuses, user, promoCode = '') {
    let products = listOrderProducts(cartItems);

    //console.log("products: "+JSON.stringify(products));
    if (!orderId) {
        return {};
    }

    let response = await fetch(getEnvOfStorage()?.apiUrl + `/loyalty/set/order?api_token=${user?.api_token}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
            orderId: orderId.toString(),
            calculationQuery: {
                client: {
                    phoneNumber: user?.phone
                },
                shop: {
                    code: "web-shop",
                    name: "mateflowers-ru"
                },
                rows: products,
                applyBonuses: +applyBonuses,
                promocode: promoCode,
            }
        })
    });
    return await response.json();
}

/**
 * Сгенерирует и вернет уникальный код заказа.
 * @returns {null|string}
 */
export function generateOrderIdForCart() {
    let cart = retrieveData(CART);
    let orderId = retrieveData(CART_ORDER_ID);
    /*
        if ((cart?.length > 0)) {
            if (!orderId) {
                orderId = uuidv4();
                storeData(CART_ORDER_ID, orderId);
            }
        } else {
            storeData(CART_ORDER_ID, '');
        }
        console.log("orderId: "+orderId, !orderId );
        */
    orderId = uuidv4();
    storeData(CART_ORDER_ID, orderId);
    //console.log("orderId: "+orderId, !orderId );
    return retrieveData(CART_ORDER_ID);
}

/**
 * Удаление страрого кода заказа orderId
 */
export function clearOrderId() {
    storeData(CART_ORDER_ID, null);
}

export {
    CART,
    CART_ORDER_ID,
}

