import React from "react";
import './index.scss'

/**
 * Универсальый компонент "Иконка - Надпись - Значение - Размерность"
 * @param {JSX.Element} icon
 * @param {string} caption
 * @param {number} value
 * @param {string} dim
 * @returns {JSX.Element}
 * @constructor
 */
const UiCaptionIconValue = ({icon, caption, value, dim}) => {
    return(
            <div className="row-flex-panel-nowrap my-08">
                <div className="mr-05">{icon}</div>
                <p>{`${caption}: ${value || 0} ${dim || ''}`}</p>
            </div>
    );
}

export default UiCaptionIconValue;