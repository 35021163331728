import React from "react";

export default function StartOff({onClick, w, h, color}) {
    return (
        <div onClick={onClick}>
            <svg width={w || 22} height={h || 22} fill={color || 'white'}  viewBox="0 0 16 16">
                <path d="M7.99993 11.6875L11.5624 13.5625C11.6041 13.5728 11.6406 13.5677 11.6719 13.547C11.7033 13.5263 11.7138 13.495 11.7034 13.453L11.0314 9.48401L13.9064 6.67151C13.9378 6.64017 13.9483 6.60634 13.9379 6.57001C13.9276 6.53367 13.9016 6.51017 13.8599 6.49951L9.87543 5.92151L8.09393 2.31201C8.07293 2.27034 8.0416 2.24951 7.99993 2.24951C7.95827 2.24951 7.92693 2.27034 7.90593 2.31201L6.12443 5.92151L2.13993 6.49951C2.09827 6.50984 2.07227 6.53334 2.06193 6.57001C2.0516 6.60668 2.0621 6.64051 2.09343 6.67151L4.96843 9.48401L4.28093 13.453C4.28093 13.4947 4.2966 13.526 4.32793 13.547C4.35927 13.568 4.39577 13.5732 4.43743 13.5625L7.99993 11.6875ZM4.90643 14.4375C4.70843 14.5418 4.50793 14.5862 4.30493 14.5705C4.10193 14.5548 3.91443 14.4845 3.74243 14.3595C3.57043 14.2345 3.44543 14.0757 3.36743 13.883C3.28943 13.6903 3.26593 13.4898 3.29693 13.2815L3.89093 9.82851L1.39093 7.39101C1.2346 7.24501 1.1331 7.07051 1.08643 6.86751C1.03977 6.66451 1.05027 6.46134 1.11793 6.25801C1.1856 6.05467 1.2976 5.88801 1.45393 5.75801C1.61027 5.62801 1.7926 5.54734 2.00093 5.51601L5.46993 5.01601L7.01693 1.87551C7.1106 1.67751 7.2461 1.52651 7.42343 1.42251C7.60077 1.31851 7.79343 1.26634 8.00143 1.26601C8.20943 1.26567 8.4021 1.31784 8.57943 1.42251C8.75677 1.52717 8.89227 1.67817 8.98593 1.87551L10.5329 5.01601L14.0019 5.51601C14.2103 5.54734 14.3951 5.62801 14.5564 5.75801C14.7178 5.88801 14.8298 6.05467 14.8924 6.25801C14.9551 6.46134 14.9629 6.66451 14.9159 6.86751C14.8689 7.07051 14.7674 7.24501 14.6114 7.39101L12.1114 9.82851L12.7054 13.2815C12.7368 13.4898 12.7133 13.6903 12.6349 13.883C12.5566 14.0757 12.4316 14.2345 12.2599 14.3595C12.0883 14.4845 11.9008 14.5548 11.6974 14.5705C11.4941 14.5862 11.2936 14.5472 11.0959 14.4535L8.00193 12.813L4.90643 14.4375Z" fill="#08080A" fill-opacity="0.96"/>
            </svg>
        </div>
    )
}