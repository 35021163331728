import React, { Component } from 'react';

import './UiFreeGiftCard.scss';

class UiFreeGiftCard extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="free-gift-card">
        <div className="free-gift-card-image">
          <img src={this.props.image} />
        </div>
        <div className="free-gift-card-info">
          <h3>{this.props.title}</h3>
          {this.props.text ?
            <p>{this.props.text}</p>
            : null
          }
        </div>
      </div>
    );
  }
}
export default UiFreeGiftCard;