import React from "react";
import UiCaptionIconValue from "../captions/UiCaptionIconValue";
import TruckSvg from "../../Svg/TruckSvg";

const UiDeliverPriceCaption = ({price}) => {
    return (
        <div className="bonus-gift-panel">
          <UiCaptionIconValue icon={<TruckSvg  h={22} w={22} />} caption="Доставка" value={price} dim="₽"/>
        </div>
    );
}

export default UiDeliverPriceCaption;