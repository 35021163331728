import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch , Redirect } from "react-router-dom";

import ProtectedRoute from "../ProtectedRoute";

import {
    HomePage,
    BridePage,
    BasketPage,
    OrderPage,
    OrderSuccessPage,
    ProfilePage,
    GiftsPage,
    ClassesPage,
    BoxesPage,
    EventsPage,
    SpecialPage,
    SubscribePage,
    AboutPage,
    DeliveryPage,
    ContactsPage,
    PrivacyPage,
    AgreementPage,
    BackPage,
    LoyaltyPage
} from "../";

class Container extends Component {
  render() {
    return (
      <Switch>
         
          <Route exact path="/" component={HomePage} />
          <Route exact path="/flowers/:flower" component={HomePage} />

          <Route exact path="/bride" component={BridePage} />
          <Route exact path="/bride/:bride" component={BridePage} />

          <Route exact path="/gifts" component={GiftsPage} />
          <Route exact path="/gifts/:category" component={GiftsPage} />
          <Route exact path="/gifts/:category/:gift" component={GiftsPage} />
          <Route exact path="/gifts/:category/:subcategory" component={GiftsPage} />
          <Route exact path="/gifts/:category/:subcategory/:gift" component={GiftsPage} />
          <Route exact path="/gifts/:category/:subcategory/:subtwocategory" component={GiftsPage} />
          <Route exact path="/gifts/:category/:subcategory/:subtwocategory/:gift" component={GiftsPage} />

          <Route exact path="/classes" component={ClassesPage} />
          <Route exact path="/classes/:id" component={ClassesPage} />

          <Route exact path="/boxes" component={BoxesPage} />
          <Route exact path="/boxes/:id" component={BoxesPage} />

          <Route exact path="/events" component={EventsPage} />
          <Route exact path="/events/:id" component={EventsPage} />

          <Route exact path="/subscribe" component={SubscribePage} />
          <Route exact path="/subscribe/:id" component={SubscribePage} />

          <Route exact path="/special/:name" component={SpecialPage} />
          <Route exact path="/special/:name/:id" component={SpecialPage} />

 
          <Route exact path="/special/:id" component={SpecialPage} />


          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/delivery" component={DeliveryPage} />
          <Route exact path="/contacts" component={ContactsPage} />

          <Route exact path="/loyalty" component={LoyaltyPage}/>

          <Route path="/basket" exact component={BasketPage} />
          <ProtectedRoute path="/order" exact component={OrderPage} />
          <Route path="/success" exact component={OrderSuccessPage} />
          <ProtectedRoute path="/profile" exact component={ProfilePage} />
 
           
          <Route path="/policy" exact component={PrivacyPage} />
          <Route path="/agreement" exact component={AgreementPage} />
          <Route path="/back" exact component={BackPage} />

          <Route component={HomePage} />
        
      </Switch>
    );
  }
}

export default Container;
