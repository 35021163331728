import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./Store/Store";

var WebFont = require('webfontloader');

WebFont.load({
  google: {
    families: ['Montserrat:200,300,400,500,600,700']
  }
});

const clientRender = () => {
  ReactDOM.render(
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>,
    document.getElementById('root')
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const serverRender = () => { };

if (typeof window === 'object') {
  clientRender();
} else {
  serverRender();
};