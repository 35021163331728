import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as SvgArrLeft } from "../../assets/images/ui/arrow_left.svg";
import { ReactComponent as SvgArrRight } from "../../assets/images/ui/arrow_right.svg";

import { useContainerDimensions } from "../../hooks/useContainerDimensions";

import UiCatalogCard from "../cards/UiCatalogCard";

import "./UiCatalog.scss";

const UiCatalogSlider = (props) => {
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  const [activeSlide, setSlideActive] = useState(0);
  const [slidesArr, setSlides] = useState([]);
  const [translate, setTranslate] = useState(0);

  const [xDown, setXDown] = useState(null);
  const [yDown, setYDown] = useState(null);

  {/*const slideW = slidesArr.length > 4 ? width - 60 : width;*/ }
  const [slideW, setSlideW] = useState(slidesArr.length > 4 ? width - 60 : width);
  const [arrCount, setArrCount] = useState(0);

  if (width > 984) {
    var cardW = slideW / 4;
    var cardWOffset = 4;
  } else {
    var cardW = slideW / 2;
    var cardWOffset = 2;
  }

  useEffect(() => {
    setTranslate(-activeSlide * slideW);
  }, [width]);

  useEffect(() => {
    setSlideW(slidesArr.length > 1 ? width - 60 : width - 60);
    let c = 0;
    slidesArr.map((item) => {
      c += item.length;
    })
    setArrCount(c);
  }, [slidesArr]);

  useEffect(() => {
    let mainArr = [],
      arr = [],
      filterArr = [],
      max = 0;

    props.slidesList.map((item, index) => {
      if (props.type == item.type && props.max > max) {
        max++;
        filterArr.push(item);
      }
    });


    filterArr.map((item, index) => {
      arr.push(item);
      if (arr.length == 4) {
        mainArr.push(arr);
        arr = [];
      }
    });
    if (arr.length > 0) {
      mainArr.push(arr);
      arr = [];
    }
    setSlides(mainArr);
  }, [props.slidesList]);

 
 

  function nextPress() {
    if (activeSlide < (arrCount - 3)) {
      let n_val = activeSlide + 1;
      setSlideActive(n_val);
      setTranslate(-n_val / cardWOffset * slideW);
    }
 
  }

  function prevPress() {
    if (activeSlide > 0) {
      let n_val = activeSlide - 1;
      setSlideActive(n_val);
      setTranslate(-n_val / cardWOffset * slideW);
    }
  }

 
    function getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      ); // jQuery
    }
  
    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      setXDown(firstTouch.clientX);
      setYDown(firstTouch.clientY);
    }
    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }
  
      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;
  
      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
  
      if (Math.abs(xDiff) > Math.abs(yDiff)) { 
        if (xDiff > 0) { 
          nextPress();
        } else { 
          prevPress();
        }
      } else {
        if (yDiff > 0) { 
        } else { 
        }
      } 
      setXDown(null);
      setYDown(null);
    }
  


  var slides = slidesArr.map((item, index) => {
    let sub = item.map((item2, index2) => {
      return (
        <UiCatalogCard
          key={index2}
          category={item2.category}
          filter={null}
          imageList={item2.imgs}
          price={item2.price + " ₽"}
          title={item2.title}
          width={cardW}
          shopClick={() => props.shopClick(item2)}
          openClick={() => props.openClick(item2)}
        />
      );
    });
    return (
      <div
        key={index}
        className="catalog-slider-slide" 
      >
        {sub}
      </div>
    );
  });

  return (
    <div className={"catalog-wrap"}  >
      {slides.length != 0 ? <h3 className="catalog-slider-title">{props.categoryName}</h3> : null}
      <div ref={componentRef} className="catalog-slider">
        {width ? (
          <div
            className="catalog-slider-scroll"
            //onTouchStart={(ev) => this.handleTouchStart(ev)}
            //onTouchMove={(ev) => this.handleTouchMove(ev)}
            style={{transform: `translateX(${translate}px)`}}
          >
            {slides}
          </div>
        ) : null}
      </div>
      {slides.length != 0 ? <button
        onClick={() => prevPress()}
        className="catalog-slider-button prev"
      >
        <SvgArrLeft className="catalog-slider-button-icon" />
      </button> : null}
      {slides.length != 0 ? <button
        onClick={() => nextPress()}
        className="catalog-slider-button next"
      >
        <SvgArrRight className="catalog-slider-button-icon" />
      </button> : null}
    </div>
  );

};
export default UiCatalogSlider;
